import { Component } from 'react'
import axios from 'axios';
import {API_CONFIG} from "../../config/api-config";

export class cardsService extends Component {

    constructor(props) {
        super(props);
        this.params = {
            apiUrlFeeds: API_CONFIG.concat('/feeds/'),
            apiUrl: API_CONFIG.concat('/trello/'),
            apiCalUrl: API_CONFIG.concat('/calendar/'),
            calendarApi:"http://localhost:9089/java-native/getUpAuth/"
        };
    }


    /*Get ALL LANES PER BOARDS*/
    GetAllLanesPerboard = (data) => {
      const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'getCardLanesPerBoard?boardId=' + data.boardId + '&key=' + data.key + '&token=' + data.token, headers)
    }



    /*Create conservation*/
    createConservationCard = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'createConservationCards?key=' + data.key + '&token=' + data.token  + '&displayName=' + data.displayName
             + '&memberId=' + data.memberId  + '&employeeNo=' + data.employeeNo + '&samaccountName='  +  data.samaccountName  , headers)
    }


    /*Get ALL TELLO BOARDS PER LANE*/
    GetAllTrelloCardsPerLane = (data, boardid) => {

        console.log("END POINT----->" + this.params.apiUrl + 'getAllCardsPerLane?boardId=' + data.boardid + '&key=' + data.key + '&token=' + data.token)
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'getAllCardsPerLane?boardId=' + boardid + '&key=' + data.key + '&token=' + data.token, headers)
    }
    /*MOVE CARD TO LANE*/
    MoveCardToLane = (data) => {
        debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.put(this.params.apiUrl + 'moveCardToLane?samaccountName=' + data.samaccountName + '&employeeNum='  + data.employeeNo, data, headers)
    }
    /*ADD CARD TO LANE*/
    addCardToLane = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.post(this.params.apiUrl + 'createCard?samaccountName=' + data.samaccountName + '&employeeNum='  + data.employeeNo, data, headers)
    }
    /*REMOVE CARD FROM LANE*/
    DeleteCard = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        return axios.put(this.params.apiUrl + 'updateCardStatus?samaccountName=' + data.samaccountName + '&employeeNum='  + data.employeeNo, data, headers)
    }

    /*Get ALL MEMBERS PER BOARD*/

    GetAllMembersPerBoard = (data) => {

        //  debugger;;
        console.log("END POINT----->" + this.params.apiUrl + 'getBoardMembers?idBoard=' + data.boardId + '&key=' + data.key + '&token=' + data.token)
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'getBoardMembers?idBoard=' + data.boardId + '&key=' + data.key + '&token=' + data.token, headers)
    }


    /*ADD MEMBER TO CARD*/
    AddMemberToCard = (data) => {
     
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
           return axios.post(this.params.apiUrl + 'addMemberToCard?cardId=' + data.cardId + '&idMember=' + data.idMember + '&samaccountName=' + data.samaccountName + '&employeeNum='  + data.employeeNum +  '&key=' + data.key + '&token=' + data.token, headers)
    }

    /*UPDATE CARD DETAILS */
    UpdateCardDetails = (data) => {
          const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.put(this.params.apiUrl + 'updateCard?samaccountName=' + data.samaccountName + '&employeeNum='  + data.employeeNo, data, headers)
    }

    /**Delete member/s from Cards */
    removeMemberAssignedCard = (data) => {
        let headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
      //  alert(this.params.apiUrl + 'removeMemberFromCard?cardId=' + data.cardId + '&idMember=' + data.idMember +  "&samaccountName=" + data.samaccountName + '&employeeNum='  + data.employeeNo + '&key=' + data.key + '&token=' + data.token);
        return axios.delete(this.params.apiUrl + 'removeMemberFromCard?cardId=' + data.cardId + '&idMember=' + data.idMember +  "&samaccountName=" + data.samaccountName + '&employeeNum='  + data.employeeNo + '&key=' + data.key + '&token=' + data.token,  headers);
    }


    retrieveCardMembers = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        return axios.get(this.params.apiUrl + 'retrieveCardMember?cardId=' + data.cardId + '&key=' + data.key + '&token=' + data.token, headers)

    }


    /*UPDATE CARD DETAILS */
    GetAllCardLabels=()=>{
    debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return  axios.get(this.params.apiUrl  + 'getCardLabels'  , headers)
    }


    retrieveOutcomes = (TaskName) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        return axios.get(this.params.apiUrl + 'lookUpOutComesPerTaskName?taskname=' + TaskName, headers)

    }


    retrieveProductData = () => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrlFeeds + 'getFilteredSolutions' , headers)
    }




    /**Delete member/s from Cards */
    HandelLabels = (data) => {
        let headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.post(this.params.apiUrl + 'addLabelToCard?cardId=' + data.cardId + '&color=' + data.color.toLowerCase() + '&name=' + data.name +   "&samaccountName=" + data.samaccountName + '&employeeNum='  + data.employeeNo  +  '&key=' + data.key +  '&token=' + data.token, headers);
    }

     /*Get Calendar Office 365 */

     GetCalendarO365 = (data) => {
       debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization":'Basic '+localStorage.getItem("mmih_flow")
        };
        return axios.post(this.params.apiCalUrl + 'userCalendarEvents',{
        }, {headers:headers})
    }

    /*Get Calendar Office 365 */

    CheckOffice365EventExist = (data) => {
    debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'accessToken':localStorage.getItem("mmih_accessToken")
        };
       

        return axios.post(this.params.apiCalUrl + 'getCalendarEventContent',data, {
            headers: headers
        })

    }

    createOffice365Event = (data) =>{
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'accessToken':localStorage.getItem("mmih_accessToken")
        };
      
        return axios.post(this.params.apiCalUrl + 'createCalendarEventsV2',data, {
            headers: headers
          })
    }

    createOffice365EventV1 = (data) =>{
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'accessToken':localStorage.getItem("mmih_accessToken")
        };
       
        return axios.post(this.params.apiCalUrl + 'createCalendarEvents',data, {
            headers: headers
          })
    }


    updateCalendarEventDates=(data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.put(this.params.apiCalUrl + 'updateIsDeleted?eventId=' + data.eventId, headers)
    }


    /*Create Outcome */
    CreateOutcome = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.post(this.params.apiUrl + 'insertOutcome?task_name=' + data.task_name.toString(), data, headers)
    }

    /*UPDATE CARD DETAILS */
    updateEventDates = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.put(this.params.apiCalUrl + 'updateCalendarEventDates', data, headers)
    }

     /* SEARCh */
     SearchAllTrelloCards = (data) => {
       
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'search?key=' + data.key + '&token=' + data.token+'&query='+data.query, headers);
    }

    /****Update Calendar ms graph */
    updateOffice365Event = (data) =>{
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'accessToken':localStorage.getItem("mmih_accessToken")
        };
        
        return axios.post(this.params.apiCalUrl + 'updateCalendarEvents',data, {
            headers: headers
          })
    }

    /****Delete Calendar ms graph */
    deleteOffice365Event = (data) =>{
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            'accessToken':localStorage.getItem("mmih_accessToken")
        };
        
        return axios.post(this.params.apiCalUrl + 'deleteCalendarEvents',data, {
            headers: headers
          })
    }

    retrieveWarehouseCalendarEventStoreInfo = (data) => {
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        const query = {
            query: {
                match: {
                    _cardId:data.cardId,
                    _eventId: data.eventId,
                    _userKey: '',
                    _userToken: ''
                }
            }
        }        

        return axios({
            method: "post",
            url: this.params.apiCalUrl+`retrieveWarehouseEventInfo`,
            headers: {'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*"},
            data: {
                _cardId:data.cardId,
                _eventId: data.eventId,
                _userKey: data.key,
                _userToken: data.token
            }
          });
       
    }

    lookUpAllCardDetailStore = (data) => {

        const lookUpAllCardDetailStoreFunc =(data) =>{
            let response = '';
            if(data.cardId !== '' && data.eventId !== ''){

                response = this.params.apiUrl+`lookUpAllCardDetailStore/access/${data._userKey}/token/${data._userToken}?cardId=${data.cardId}&eventId=${data.eventId}`;
            }else if(data.cardId !== ''){
                response = this.params.apiUrl+`lookUpAllCardDetailStore/access/${data._userKey}/token/${data._userToken}?cardId=${data.cardId}`;

            }else{
                response = this.params.apiUrl+`lookUpAllCardDetailStore/access/${data._userKey}/token/${data._userToken}?eventId=${data.eventId}`;
            }

            return response;
        }
        debugger;

       // GET request for remote image
       return  axios({
            method: 'get',
            url: lookUpAllCardDetailStoreFunc(data),
            headers: {'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*"}
        });
            

    }

    /*Get ALL MEMBERS PER BOARD*/

    CheckCardEventRecord = (data) => {

        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        return axios.get(this.params.apiUrl + 'getCardEventRecord?cardId=' + data.cardId , headers)
    }

       /*Get ALL Info PER BOARDS*/
       GetBoardInfoDetails = (data) => {
        const headers = {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*"
          };
          return axios.get(this.params.apiUrl + 'getBoardDetailsById/boardId/' + data.boardId + '/key/' + data.key + '/token/' + data.token, headers)
      }

      /** Adding members to Board */
      addMemberToBoard = (data) => {        
        debugger;
        return axios({
            method: "post",
            url: this.params.apiUrl+`addMemberBoard`,
            headers: {'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*"},
            data: data
          });
       
    }

     /** remove members on a Board */
     removeMemberFromBoard = (data) => {
         debugger;
        
        return axios({
            method: "post",
            url: this.params.apiUrl+`removeMemberBoardViaMemberId?boardId=${data.boardId}&key=${data.key}&token=${data.token}&removeMemberId=${data.removeMemberId}`,
            headers: {'Content-Type': 'application/json',"Access-Control-Allow-Origin": "*"}
            
          });
       
    }




}


export default cardsService;
