import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
// import Carousel from "re-carousel";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import StoreService from "./../shared/StoreService";
import InsertEmoticonOutlinedIcon from '@material-ui/icons/InsertEmoticonOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import NotificationsIcon from '@material-ui/icons/Notifications';
import Carousel from 'react-grid-carousel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Tooltips from '@material-ui/core/Tooltip';





import {
  Line,
  LineChart,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  AreaChart,
  Area
} from "recharts";
import { GraphData } from "./constantNavigate.js";
import avatarService from "../shared/AvatarService";
import boardsService from "../shared/boardsService";
import CardsService from '../shared/cardService';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import CardThirdPartyEntity from "./Widget/CardThirdPartyEntity";
import CarouselButtons from "./Widget/CarouselButtons";
import CarouselIndicatorDots from "./Widget/CarouselIndicatorDots";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DashboardIcon from "@material-ui/icons/Dashboard";
import avatar from "../../images/avatar.svg";
import {Extension} from "@material-ui/icons";
import Loader from "react-loader-spinner";
import Drawer from '@material-ui/core/Drawer';
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {Badge} from "@material-ui/core";

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    fill: "#9e9e9e"
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    borderRadius: "3px"
  },
  image: {
    width: 128,
    height: 128
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  card: {
    maxWidth: 345
  },
  cardWrapInfo: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  media: {
    height: 120
  },
  dashboardLayout: {
    boxsizing: "border-box",
    boxshadow:
      "0px 1px 3px 0px #00000033, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #0000001f",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundcolor: "#fff",
    flexwrap: "wrap"
  },
  inline: {
    display: "inline"
  },
  tableWrapper: {
    maxHeight: 240,
    overflow: "auto"
  },
  quoteText: {
    color: "#663399",
    paddingTop: "10px",
    fontWeight: 600
  },
  quoteText2: {
    marginRight: "5px",
    color: "#7a787d"
  },
  dashboardIcon: {
    marginRight: "1px",
    color: "#673ab7",
    textAlign: "center"
  },
  taskWidth: {
    width: '100%'
  },
  alignLoader:{
    textAlign: 'center'
  }
  ,
  fullList: {
    width: 'auto',
  },
  alignTasksLoader: {
    marginLeft: 'auto'
  },
  alignCounter: {
    marginLeft: 15
  },
  textPrimary: {
    fontWeight: 500
  },
  avatarListCklr:{
    backgroundColor:'rgb(126, 57, 251)',
    color:'white'

},
});

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    borderRadius: "5px",
    "&:not(:last-child)": {
      borderBottom: 0
    },

    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    borderRadius: "5px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMenuZ = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
     // onClick={}
      {...props}
    />
  ));

  const StyledMenuItemZ = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: 'rgb(126, 57, 251)',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

class HomeLayoutHub extends Component {
  constructor(props) {
    super(props);


    this.state = {
      expanded: "panel1",
      getUpRss: [],
      userLog: this.props.userLog,
      todayAssignedTasks: [],
      assignedTasks: [],
      OutstandingAssignedTasks: [],
      assignedTasksFullObject: [],
      getInspirationalQuote: {
        text: `Waiting Clients ...... Truncation should be conditionally applicable on this long line of text
      as this is a much longer line than what the container can support. \n
      Truncation should be conditionally`,
        author: "GetUp"
      },
      getAvatarUI: this.props.userAvatarUI,
      anchorEl: null,
      solutions: [],
      bottom:false,
      userCardInfo:{
        name:'',
        labels:[],
        desc:'',
        boardsInfoDTO:{
          name:''
        },
        due:'',
        taskType: ''

      },
      userCardInfoLane:'',
      isAssignedTasksLoaded: true,
      isLoading: true,
      todaysCounter: 0,
      assignedCounter: 0,
      outstandingCounter: 0,
      openDialogbottom:false,
      getAllMembersPerBoard:[],
      getAllAvatarCardMembers:[],
      _listPopUpAnchorEl: null,
      _listPopUpGetAllLanesPerboard:[]
    };

    this.parseCurrentTime = this.parseCurrentTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.avatarService = new avatarService();
    this.boardsService = new boardsService();
    this.StoreService = new StoreService();
    this.CardsService = new CardsService();
  }

  componentDidMount() {
    // this.avatarService
    //   .GetDigitalLastestFeeds()
    //   .then(res => res.data)
    //   .then(response => {
    //
    //     this.setState({ getUpRss: response });
    //   })
    //   .catch(error => {
    //     console.log("Service GetDigitalLastestFeeds ", error);
    //   });



    this.boardsService
      .GetMemberInformation({
        key: this.props.data.key,
        token: this.props.data.token
      })
      .then(res => {
        return this.boardsService.GetAdviserAssignedMemberCardsInformation({
          idMember: res.data.id,
          key: this.props.data.key,
          token: this.props.data.token
        });
      })
      .then(response => {

        let todayAssignedTasks = [],
          assignedTasks = [],
          OutstandingAssignedTasks = [];
        let taskType = '';

        response.data.forEach(function (tasks) {

          tasks.boardsInfoDTO.lists.forEach(laneInfo => {

            let Ideas = 'Ideas', Backlog = 'Backlog', InProgress = 'In Progress', to_do = 'To do', currentLaneName = laneInfo.name;
            let currentDate = new Date();
            let storeCurrentDate = [], storeCardtaskDueDate = [];
            let task_date = new Date(tasks.badges.due);
            let current_date = currentDate;
            let isvalidAssignedTasks = false;
            let isvalidOutstandingTask = false;
            let isValidTodaysTasks = false;
            if(typeof tasks.badges.due !== 'undefined'){
              if(current_date != null && task_date != null){
                console.log("Current Date: " + current_date + ", Task Due Date: " + task_date);
                debugger;
                if(task_date.getDate() === current_date.getDate()){
                  isValidTodaysTasks = true
                }else if(task_date.getDate() < current_date.getDate()){
                  isvalidOutstandingTask = true;
                }else if(task_date.getDate() > current_date.getDate()){
                  isvalidAssignedTasks = true;
                }

              }
            }

            if ((laneInfo.id === tasks.idList) && (currentLaneName.toLowerCase() === Backlog.toLowerCase() || currentLaneName.toLowerCase() === InProgress.toLowerCase() || currentLaneName.toLowerCase() === to_do.toLowerCase())) {

              if(isValidTodaysTasks){
                taskType = 'Today';
                todayAssignedTasks.push(tasks);
              }else if(isvalidOutstandingTask){
                OutstandingAssignedTasks.push(tasks);
                taskType = 'Outstanding';
              }else if(isvalidAssignedTasks){
                assignedTasks.push(tasks);
                taskType = 'Assigned';
              }
            }
          });
        });
        this.setState({
          todayAssignedTasks: todayAssignedTasks,
          assignedTasks: assignedTasks,
          OutstandingAssignedTasks: OutstandingAssignedTasks,
          assignedTasksFullObject: response.data,
          isAssignedTasksLoaded: false,
          taskType: taskType,
          todaysCounter: todayAssignedTasks.length,
          assignedCounter: assignedTasks.length,
          outstandingCounter: OutstandingAssignedTasks.length
        });
      }).catch(error => {
        console.log("Service GetAdviserAssignedMemberCardsInformation ", error);
      });

    this.avatarService
      .GetTodaysInspirationalQuote()
      .then(res => res.data)
      .then(response => {
        this.setState({
          getInspirationalQuote:
            response[Math.floor(Math.random() * response.length)],
          isLoading: false
        });
      }).catch(error => {
        console.log("Service GetTodaysInspirationalQuote ", error);
      });
    this.avatarService
      .GetTrelloBoardAvatar(this.props.data)
      .then(res => res.data)
      .then(response => {
          this.setState({
            getAvatarUI: response,
            isLoading: false
          });
        },error => {
          console.log("Error response Avatar", error);
        }
      );

      this.StoreService
      .getSolutionsData()
      .then(res => res.data)
      .then(response => {

         if(response.length > 0){
           let fourDefaultSolution = [], uniqueSet = new Map();
           for(let t=0; t< response.length; t++){
            // let randomNum = Math.floor(Math.random() * response.length);          use this if they are more than 4, substutited t with
             uniqueSet.set(response[t].title,response[t]);

            //  if(uniqueSet.size === 4){
            //
            //   for (let [key, value] of uniqueSet.entries()){
            //
            //
            //   fourDefaultSolution.push(value);
            // }
            //   break;
            //  }
           }

           this.setState({
            solutions: response,
             isLoading: false
          });
         }

      },error => {
        console.log("Error returning solutions", error)
      });
}

handleMoveLaneClick = (event) =>{

        //this.handleMoveLaneItemClick(event, 0, value);
        debugger;
        this.setState({
            _listPopUpAnchorEl:event.currentTarget
        });
    }
    handleMoveLaneClickClose = () => {
        this.setState({
            _listPopUpAnchorEl:null
        });
      };

handleMoveLaneItemClick =(event, index, selected) => {
        debugger;
        const objMoveCard = {
            key: this.props.data.key,
            token: this.data.token,
            cardId: this.state._searchCardId,
            laneId: '',
            sourceLaneId: this.state._searchCardBoardCurrentLaneId,
            position: "",
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName,
            MycardContent: {
                cardId: this.state._searchCardId,
                cardName: this.state._title,
                cardDesc: this.state._cardDesc,
                position: 'top',
                dueDate: this.state._dueDate,
                closed: false,
                taskId: 0,
                laneId: this.state._searchCardBoardCurrentLaneId,
                key: this.props.data.key,
                token: this.props.data.token,
                worksiteId: this.state._searchCardBoardId
            }
        }

            this.moveCardToLane(objMoveCard)

    };

popUpAvatar =(e, getAllAvatarCardMembers)=>{
  debugger;
  const holder =getAllAvatarCardMembers.map((avatarInfo, index)=>{

    return (<Tooltip title={avatarInfo.fullName}>
                                  <Avatar alt={avatarInfo.fullName} key={index} style={{backgroundColor: 'blue',color: 'white'}}>{avatarInfo.fullName.charAt(0)}</Avatar>
      </Tooltip>)
    })
    debugger;
    return holder;
}

toggleDrawer = (side, open, userCardInfo) => event => {

  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }
let userCardInfoLane = '';
for(var list = 0; list < userCardInfo.boardsInfoDTO.lists.length ;list++){
  if(userCardInfo.idList === userCardInfo.boardsInfoDTO.lists[list].id){
    userCardInfoLane = userCardInfo.boardsInfoDTO.lists[list].name;
  }
}


  this.setState({ [side]: open, userCardInfo:userCardInfo, userCardInfoLane: userCardInfoLane });
};

handleDailogClose =()=>{
  debugger;
  this.setState({
    openDialogbottom:false,
    getAllMembersPerBoard:[],
    getAllAvatarCardMembers:[]
  })
}

handleDialogClick =(event ,userCardInfo) =>{
  debugger;
  this.setState({getAllMembersPerBoard:[],getAllAvatarCardMembers:[]});
  this.CardsService.GetAllMembersPerBoard({boardId:userCardInfo.idBoard,key: this.props.data.key,
    token: this.props.data.token}).then(response=>{
      let avatarMemberholder =[];
      for(let dd=0;dd<response.data.length;dd++){
        for(let dt = 0; dt<userCardInfo.idMembers.length;dt++){
          if(response.data[dd].id ===userCardInfo.idMembers[dt]){
            avatarMemberholder.push(response.data[dd]);
          }
        }
      }
      debugger;
      this.setState({getAllMembersPerBoard:response.data,getAllAvatarCardMembers:avatarMemberholder})},
      error =>{
        debugger;
        this.setState({getAllMembersPerBoard:[],getAllAvatarCardMembers:[]})
      });
  let userCardInfoLane = '';
for(var list = 0; list < userCardInfo.boardsInfoDTO.lists.length ;list++){
  if(userCardInfo.idList === userCardInfo.boardsInfoDTO.lists[list].id){
    userCardInfoLane = userCardInfo.boardsInfoDTO.lists[list].name;
    this.CardsService.GetAllLanesPerboard({ boardId: userCardInfo.boardsInfoDTO.id, key: this.props.data.key, token: this.props.data.token }).then(
                    response => {
                        if (response.data !== null ) {
                            this.setState({
                                _listPopUpGetAllLanesPerboard: response.data,
                            })
                        }
                    }, error => {
                    this.setState({
                        _listPopUpGetAllLanesPerboard: [],
                    });
                    });
  }
}

  this.setState({ openDialogbottom: true, userCardInfo:userCardInfo, userCardInfoLane: userCardInfoLane });

}

parseCurrentTime = username => {
  const currentTimeStamp = new Date().getHours();
  if (username.sub.givenName) {
    return currentTimeStamp < 12
      ? "Good morning, " + username.sub.givenName + "!"
      : currentTimeStamp >= 12 && currentTimeStamp < 18
        ? "Good day, " + username.sub.givenName + "!"
        : "Good evening, " + username.sub.givenName + "!";
  } else {
    var res = username.sub.displayName.split(" ");
    var name = res[0];
    return currentTimeStamp < 12
      ? "Good morning, " + name + "!"
      : currentTimeStamp >= 12 && currentTimeStamp < 18
        ? "Good day, " + name + "!"
        : "Good evening, " + name + "!";
  }
};

  userAvatarImage = () => {
    return <Avatar data={this.props.data} />;
  };

  handleChange = (event, panelObj, newExpanded) => {
    this.setState({
      expanded: !newExpanded ? panelObj : false
    });
  };
  raiseInvoiceClicked = (event, data) => {
    const url = data.link;
    window.open(url, "_blank");
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { classes } = this.props;
    const solutionsList = this.state.solutions;
    const totalItems = solutionsList.length ? solutionsList.length : 3;

    const ColoredLine = ({ color }) => (
      <hr
          style={{
              color: color,
              backgroundColor: color,
              height: 10,
              borderColor : color
          }}
      />
  );

   const fullList = side => (
      <div
        className={classes.fullList}
        role="presentation"
        onClick={this.toggleDrawer(side, false,this.state.userCardInfo)}
        onKeyDown={this.toggleDrawer(side, false, this.state.userCardInfo)}
      >
        <Paper className={classes.paper}>
        <ColoredLine color={(this.state.userCardInfo.labels.length > 0)?this.state.userCardInfo.labels[0].color: 'white'} />
                <Grid container wrap="nowrap" spacing={2}>

                  <Grid item xs >
                    <Typography variant="h6" gutterBottom>
                      Title/Subject
                     </Typography>
                     <Typography variant="h6" gutterBottom>
                     Card Description
                     </Typography>
                     <Typography variant="h6" gutterBottom>
                     Card Status
                     </Typography>
                     <Typography variant="h6" gutterBottom>
                     Due Date
                     </Typography>
                  </Grid>
                  <Grid item xs >
                  <Typography variant="h6" noWrap>
                       {this.state.userCardInfo.name}
                  </Typography>
                  <Typography variant="h6" noWrap>
                       {this.state.userCardInfo.desc}
                  </Typography>
                  <Typography variant="h6" noWrap>
                       {this.state.userCardInfoLane}
                  </Typography>
                  <Typography variant="h6" noWrap>
                       {this.state.userCardInfo.due}
                  </Typography>
                  </Grid>
                  <Grid item xs >

                  </Grid>
                </Grid>
              </Paper>


      </div>
    );

    const getStaticCards = () => {
      return (
        <React.Fragment>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={"https://source.unsplash.com/random"}
                title="GetUp Blog"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  GetUp Blog
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.cardWrapInfo}
                >
                  Litora corporis, accumsan dictumst quidem ante facilisis sed
                  asperiores natus officiis perferendis quisquam eleifend,
                  aperiam delectus. Fermentum facilisi elementum. Ratione
                  curabitur pariatur quas assumenda ducimus nibh duis alias
                  exercitationem molestias facilisis phasellus proin? Mi
                  phasellus? Urna. Nonummy!
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  this.raiseInvoiceClicked(e, {
                    link: "http://10.248.18.45/index.php/wp-json/wp/v2/posts/"
                  });
                }}
              >
                Apply
              </Button>
            </CardActions>
          </Card>
        </React.Fragment>
      );
    };

    const getCardFeeds = () => {
      return this.state.getUpRss !== 0
        ? this.state.getUpRss.map((rssFeedBody, index) => {
          return (
            <Card className={classes.card} key={index}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={
                    rssFeedBody.acf.featured_image !== null
                      ? rssFeedBody.acf.featured_image.icon
                      : "https://source.unsplash.com/random"
                  }
                  title={rssFeedBody.title.rendered}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {rssFeedBody.title.rendered}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.cardWrapInfo}
                    dangerouslySetInnerHTML={{
                      __html: rssFeedBody.excerpt.rendered
                    }}
                  >

                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={e => {
                    this.raiseInvoiceClicked(e, rssFeedBody);
                  }}
                >
                  Read More
                  </Button>
              </CardActions>
            </Card>
          );
        })
        : getStaticCards();
    };
    let getRssCardFeeds = getCardFeeds();
    let getUserLogCurrentTime = this.parseCurrentTime(this.state.userLog);

    const listAssignedTask = (res, taskType) => {
      if (res.length === 0) {
        if(!this.state.isAssignedTasksLoaded){
          if(taskType === 'Today'){
            return <List className={classes.taskWidth}>
              <ListItem alignItems="flex-start">
                <ListItemText
                    primary="You don’t have any tasks. "
                />
              </ListItem>
            </List>
          }else if(taskType === 'Outstanding'){
            return <List className={classes.taskWidth}>
              <ListItem alignItems="flex-start">
                <ListItemText
                    primary="You have no outstanding tasks."
                />
              </ListItem>
            </List>
          }else if(taskType === 'Assigned'){
            return <List className={classes.taskWidth}>
              <ListItem alignItems="flex-start">
                <ListItemText
                    primary="You don’t have any tasks assigned to you. "
                />
              </ListItem>
            </List>
          }
        }else{
          return <List className={classes.taskWidth}>
            <ListItem alignItems="flex-start">
              <ListItemText
                  primary=":) just busy fetching your tasks ..."
              />
            </ListItem>
          </List>
        }
      } else {
        return (
          <List className={classes.taskWidth}>
            {res.map((sectionId, index) => {
              return (
                //button onClick={this.toggleDrawer('bottom', true,sectionId)}
                <ListItem button alignItems={"flex-start"} key={index} onClick={(e)=>this.handleDialogClick(e,sectionId)}>
                  <ListItemAvatar>
                    <Avatar
                        alt="avatar"
                        src={getMemberCreatorAvatar(sectionId).createAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<React.Fragment>
                      <Typography
                          component={"h2"}
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                          style={{fontSize: "15px"}}
                      >
                        {" " + sectionId.name}
                      </Typography>
                    </React.Fragment>}
                    secondary={
                      <React.Fragment>
                        {
                          sectionId.desc ? (
                              <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    style={{fontSize: "13px", lineHeight: 1}}
                                >
                                  Description -
                                </Typography>
                                {" " + sectionId.desc}
                              </React.Fragment>
                          ) :(
                              <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    style={{fontSize: "13px", lineHeight: 1}}
                                >
                                  Description -
                                </Typography>
                                {" " + "None"}
                              </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    }
                  />

                  {/*<IconButton aria-label="settings" onClick={this.handleClick}>*/}
                  {/*  <MoreVertIcon />*/}
                  {/*</IconButton>*/}
                  {/*<StyledMenu*/}
                  {/*  id="customized-menu"*/}
                  {/*  anchorEl={this.state.anchorEl}*/}
                  {/*  keepMounted*/}
                  {/*  open={Boolean(this.state.anchorEl)}*/}
                  {/*  onClose={this.handleClose}*/}
                  {/*>*/}
                  {/*  <StyledMenuItem>*/}
                  {/*    <ListItemIcon>*/}
                  {/*      <SendIcon fontSize="small" />*/}
                  {/*    </ListItemIcon>*/}
                  {/*    <ListItemText primary="SendIcon" />*/}
                  {/*  </StyledMenuItem>*/}
                  {/*  <StyledMenuItem>*/}
                  {/*    <ListItemIcon>*/}
                  {/*      <DraftsIcon fontSize="small" />*/}
                  {/*    </ListItemIcon>*/}
                  {/*    <ListItemText primary="DraftsIcon" />*/}
                  {/*  </StyledMenuItem>*/}
                  {/*  <StyledMenuItem>*/}
                  {/*    <ListItemIcon>*/}
                  {/*      <InboxIcon fontSize="small" />*/}
                  {/*    </ListItemIcon>*/}
                  {/*    <ListItemText primary="InboxIcon" />*/}
                  {/*  </StyledMenuItem>*/}
                  {/*</StyledMenu>*/}
                </ListItem>

              );
            })}
          </List>
        );
      }
    };

    const renderLaneResultFilter = () => {
              debugger;
                let availableLane =[];

                for(let d = 0; d< this.state._listPopUpGetAllLanesPerboard.length;d++){
                    if(this.state._listPopUpGetAllLanesPerboard[d].id !== this.state.userCardInfo.idList && this.state._listPopUpGetAllLanesPerboard[d].name !== 'Done'){
                        availableLane.push(this.state._listPopUpGetAllLanesPerboard[d]);
                    }
                }
                            debugger;
                            //let res = this.state.SearchAllTrelloCardsResultSet;
                            if (availableLane.length === 0) {
                                return <StyledMenuItemZ>
                                <ListItemIcon>
                                                         <SendIcon fontSize="small" />
                                                     </ListItemIcon>
                                                     <ListItemText primary="Sent mail" />
                                </StyledMenuItemZ>

                            } else {
                                return (
                                        availableLane.map((sectionId, index) => {
                                            return (
                                                <StyledMenuItemZ
                                                 //   selected={this.state._selectedLaneSearchIndex === index}
                                                    onClick={event => this.handleMoveLaneItemClick(event, index, sectionId)}
                                                    key={index} >
                                                    <ListItemIcon>
                                                           <SendIcon fontSize="small" />
                                                       </ListItemIcon>
                                                    <ListItemText
                                                        primary={<React.Fragment>
                                                            <Typography
                                                                color="textPrimary"
                                                                style={{ fontSize: "15px" }} >
                                                                {sectionId.name}
                                                            </Typography>
                                                        </React.Fragment>}
                                                    />
                                                </StyledMenuItemZ>
                                            );

                                        }) );
                            }

                        };


    const retrievePopUpAvatar = (getAllAvatarCardMembers) =>{
      if(getAllAvatarCardMembers.length=== 0){
      return '';
      }else{
      return (
       <AvatarGroup>
         {getAllAvatarCardMembers.map((avatarInfo, index)=>{

           return (<Tooltips key={index} title={avatarInfo.fullName}>
                                         <Avatar alt={avatarInfo.fullName}   className={classes.avatarListCklr}>{avatarInfo.fullName.charAt(0)}</Avatar>
                                         </Tooltips>)
           })}
         </AvatarGroup>

      )
      }
      }

    const getMemberCreatorAvatar = (avatarInfo) => {
      let responseAvatar = '', lastestDate = '',
        responseAvatarObject = { labelColor: '', labelName: '', dueDate: '', boardName: '', laneName: '' };

      for (var i = 0; i < avatarInfo.actionDTO.length; ++i) {
        ;

        if ((typeof avatarInfo.actionDTO[i].data.card !== 'undefined')
          && (typeof avatarInfo.actionDTO[i].data.board !== 'undefined')
        ) {
          if ((avatarInfo.actionDTO[i].type === 'updateCard') &&
            (avatarInfo.name === avatarInfo.actionDTO[i].data.card.name) &&
            (avatarInfo.id === avatarInfo.actionDTO[i].data.card.id)) {

            responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
            responseAvatarObject.boardName = avatarInfo.boardsInfoDTO.name;

            if (avatarInfo.actionDTO.length === 1) {
              lastestDate = new Date(avatarInfo.actionDTO[i].date);
              responseAvatar = (avatarInfo.actionDTO[i].memberCreator.avatarUrl !== null) ? avatarInfo.actionDTO[i].memberCreator.avatarUrl : 'https://dkxsg3399amor.cloudfront.net/avatar.svg';

              responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
              responseAvatarObject.boardName = avatarInfo.boardsInfoDTO.name;
              if (avatarInfo.labels.length > 0) {
                responseAvatarObject.labelColor = avatarInfo.labels[0].color;
                responseAvatarObject.labelName = avatarInfo.labels[0].name;

              } else {

                responseAvatarObject.labelColor = '';
                responseAvatarObject.labelName = '';
              }
              avatarInfo.boardsInfoDTO.lists.forEach(labelContent => {
                if (labelContent.id === avatarInfo.boardsInfoDTO.idList) {

                  responseAvatarObject.laneName = labelContent.name;

                }
              });
            } else {
              if (lastestDate === '') {
                lastestDate = new Date(avatarInfo.actionDTO[i].date);
              } else {
                lastestDate = (lastestDate < new Date(avatarInfo.actionDTO[i].date)) ? lastestDate : new Date(avatarInfo.actionDTO[i].date);
              }

            }

          }

        }

      }
      if (lastestDate === '') {
        responseAvatar = 'https://dkxsg3399amor.cloudfront.net/avatar.svg';
        if (avatarInfo.labels.length > 0) {
          responseAvatarObject.labelColor = avatarInfo.labels[0].color;
          responseAvatarObject.labelName = avatarInfo.labels[0].name;
          responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
          responseAvatarObject.boardName = avatarInfo.boardsInfoDTO.name;
        } else {
          responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
          responseAvatarObject.boardName = avatarInfo.boardsInfoDTO.name;
          responseAvatarObject.labelColor = '';
          responseAvatarObject.labelName = '';
        }
        avatarInfo.boardsInfoDTO.lists.forEach(labelContent => {
          if (labelContent.id === avatarInfo.idList) {

            responseAvatarObject.laneName = labelContent.name;

          }
        });
      } else {
        responseAvatar = avatarInfo.actionDTO.find(element => {
          let elementDate = new Date(element.date);

          return +elementDate === +lastestDate
        });
        responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
        responseAvatarObject.boardName = avatarInfo.boardsInfoDTO.name;

        if (avatarInfo.labels.length > 0) {
          responseAvatarObject.labelColor = avatarInfo.labels[0].color;
          responseAvatarObject.labelName = avatarInfo.labels[0].name;
          responseAvatarObject.dueDate = (avatarInfo.due) ? avatarInfo.due : 'Not specified';
        } else {
          responseAvatarObject.labelColor = '';
          responseAvatarObject.labelName = '';
        }
        avatarInfo.boardsInfoDTO.lists.forEach(labelContent => {
          if (labelContent.id === avatarInfo.idList) {

            responseAvatarObject.laneName = labelContent.name;

          }
        });
        // responseAvatar = responseAvatar.memberCreator.avatarUrl !== null ? responseAvatar.memberCreator.avatarUrl : 'https://dkxsg3399amor.cloudfront.net/avatar.svg';

        responseAvatar =  'https://dkxsg3399amor.cloudfront.net/avatar.svg';

      }
      let responseAvatarBody = {
        createInfo: responseAvatarObject,
        createAvatar: avatarInfo.actionDTO.length > 0 ? responseAvatar : 'https://dkxsg3399amor.cloudfront.net/avatar.svg'
      };
      return responseAvatarBody;
    };

    return (this.state.isLoading) ? <div className={classes.alignLoader}><Loader type="ThreeDots" color="#3f51b5" height={80} width={80} /></div> : (
      <div className={classes.root}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={9}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase className={classes.image}>
                    <img
                      className={classes.img}
                      alt="complex"
                      // src={this.state.getAvatarUI.avatarUrl + "/170.png"}
                      src={avatar}
                    />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={4}>
                    <Grid item xs>
                      <Typography variant="h5" className={classes.quoteText}>
                        {getUserLogCurrentTime}
                      </Typography>
                      <Typography variant="h6" className={classes.quoteText2}>
                        <span>&#8220;</span>
                        {this.state.getInspirationalQuote.text +
                          `   -- ` +
                          this.state.getInspirationalQuote.author}
                        <span>&#8221;</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <br />
            <ExpansionPanel
              square
              expanded={this.state.expanded === "panel1"}
              onChange={e => {
                this.handleChange(
                  e,
                  "panel1",
                  this.state.expanded === "panel1"
                );
              }}
            >
              <ExpansionPanelSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <ArrowRightIcon />
                <Typography variant="inherit" component="h3">
                  Today
                </Typography>
                <div className={classes.alignTasksLoader}>
                  <Loader type="ThreeDots" color="#3f51b5" height={25} width={25}
                          visible={this.state.isAssignedTasksLoaded}/>

                </div>
                <div>
                  {
                    !this.state.isAssignedTasksLoaded ? (
                            <Badge
                                badgeContent={this.state.todaysCounter}
                                variant="standard"
                                color="primary"
                                showZero
                                className={classes.removeCol}
                            >
                            </Badge>
                        )
                        : (
                            <Badge style={{display: 'none'}}/>
                        )
                  }
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.tableWrapper}>

                {listAssignedTask(this.state.todayAssignedTasks, 'Today')}

              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
              square
              expanded={this.state.expanded === "panel2"}
              onChange={e => {
                this.handleChange(
                  e,
                  "panel2",
                  this.state.expanded === "panel2"
                );
              }}
            >
              <ExpansionPanelSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <ArrowRightIcon />
                <Typography variant="inherit" component="h3">
                  Assigned Tasks
                </Typography>
                <div className={classes.alignTasksLoader}>
                  <Loader type="ThreeDots" color="#3f51b5" height={25} width={25}
                          visible={this.state.isAssignedTasksLoaded}/>
                </div>
                <div >
                  {
                    !this.state.isAssignedTasksLoaded ? (
                            <Badge
                                badgeContent={this.state.assignedCounter}
                                variant="standard"
                                color="primary"
                                showZero
                                className={classes.removeCol}
                            >
                            </Badge>
                        )
                        : (
                            <Badge style={{display: 'none'}}/>
                        )
                  }
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                {listAssignedTask(this.state.assignedTasks, 'Assigned')}

              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
              square
              expanded={this.state.expanded === "panel3"}
              onChange={e => {
                this.handleChange(
                  e,
                  "panel3",
                  this.state.expanded === "panel3"
                );
              }}
            >
              <ExpansionPanelSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <ArrowRightIcon />
                <Typography variant="inherit" component="h3">
                  Outstanding Tasks
                </Typography>
                <div className={classes.alignTasksLoader}>
                  <Loader type="ThreeDots" color="#3f51b5" height={25} width={25}
                          visible={this.state.isAssignedTasksLoaded}/>
                </div>
                <div >
                  {
                    !this.state.isAssignedTasksLoaded ? (
                            <Badge
                                badgeContent={this.state.outstandingCounter}
                                variant="standard"
                                color="primary"
                                showZero
                                className={classes.removeCol}
                            >
                            </Badge>
                        )
                        : (
                            <Badge style={{display: 'none'}}/>
                        )
                  }
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {listAssignedTask(this.state.OutstandingAssignedTasks, 'Outstanding')}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          {/* Recent Ad or LIVE Feeds */}
          {/*<Grid item xs={12} sm={3} md={3} style={{ height: '390px' }}>*/}
          {/*  <Carousel*/}
          {/*    loop*/}
          {/*    auto*/}
          {/*    widgets={[CarouselIndicatorDots, CarouselButtons]}*/}
          {/*  >*/}
          {/*     {getRssCardFeeds}*/}
          {/*  </Carousel>*/}
          {/*</Grid>*/}
          {/**Dashboard Feature */}
          {/*<Grid item xs={12} md={8} lg={9} className={classes.dashboardLayout}>*/}
          {/*  <ExpansionPanel square expanded={true}>*/}
          {/*    <ExpansionPanelSummary*/}
          {/*      expandIcon={<ExpandMoreIcon />}*/}
          {/*      aria-controls="panel2a-content"*/}
          {/*      id="panel2a-header"*/}
          {/*    >*/}
          {/*      <DashboardIcon style={{ marginRight: "2px" }} />*/}
          {/*      <Typography variant="inherit" component="h3">*/}
          {/*        Dashboards*/}
          {/*      </Typography>*/}
          {/*    </ExpansionPanelSummary>*/}
          {/*    <ExpansionPanelDetails>*/}
          {/*      <Grid container spacing={4}>*/}
          {/*        <Grid item xs={12} sm={6} md={4}>*/}
          {/*          <LineChart width={300} height={200} data={GraphData}>*/}
          {/*            <XAxis dataKey="name" />*/}
          {/*            <YAxis />*/}
          {/*            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />*/}
          {/*            <Line type="monotone" dataKey="uv" stroke="#8884d8" />*/}
          {/*            <Line type="monotone" dataKey="pv" stroke="#82ca9d" />*/}
          {/*          </LineChart>*/}
          {/*        </Grid>*/}

          {/*        <Grid item xs={12} sm={6} md={4}>*/}
          {/*          <BarChart*/}
          {/*            width={300}*/}
          {/*            height={200}*/}
          {/*            data={GraphData}*/}
          {/*            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}*/}
          {/*          >*/}
          {/*            <CartesianGrid strokeDasharray="3 3" />*/}
          {/*            <XAxis dataKey="name" />*/}
          {/*            <YAxis />*/}
          {/*            <Tooltip />*/}
          {/*            <Legend />*/}
          {/*            <Bar dataKey="pv" stackId="a" fill="#8884d8" />*/}
          {/*            <Bar dataKey="uv" stackId="a" fill="#82ca9d" />*/}
          {/*          </BarChart>*/}
          {/*        </Grid>*/}
          {/*        <Grid item xs={12} sm={6} md={4}>*/}
          {/*          <AreaChart*/}
          {/*            width={300}*/}
          {/*            height={200}*/}
          {/*            data={GraphData}*/}
          {/*            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}*/}
          {/*          >*/}
          {/*            <CartesianGrid strokeDasharray="3 3" />*/}
          {/*            <XAxis dataKey="name" />*/}
          {/*            <YAxis />*/}
          {/*            <Tooltip />*/}
          {/*            <Area*/}
          {/*              type="monotone"*/}
          {/*              dataKey="uv"*/}
          {/*              stackId="1"*/}
          {/*              stroke="#8884d8"*/}
          {/*              fill="#8884d8"*/}
          {/*            />*/}
          {/*            <Area*/}
          {/*              type="monotone"*/}
          {/*              dataKey="pv"*/}
          {/*              stackId="1"*/}
          {/*              stroke="#82ca9d"*/}
          {/*              fill="#82ca9d"*/}
          {/*            />*/}
          {/*            <Area*/}
          {/*              type="monotone"*/}
          {/*              dataKey="amt"*/}
          {/*              stackId="1"*/}
          {/*              stroke="#ffc658"*/}
          {/*              fill="#ffc658"*/}
          {/*            />*/}
          {/*          </AreaChart>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </ExpansionPanelDetails>*/}
          {/*  </ExpansionPanel>*/}
          {/*</Grid>*/}
          {/**Quick Calendar Feature */}
          {/*<Grid item xs={12} md={4} lg={3}></Grid>*/}
          {/**Add third party entity */}
          <Grid item xs={12} md={8} lg={9} className={classes.dashboardLayout}>
            <ExpansionPanel square expanded={true}>
              <ExpansionPanelSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                {/*<Extension style={{ marginRight: "2px" }} />*/}
                <Typography variant="inherit" component="h3">
                  Solutions
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>

                <div>
                  {
                    solutionsList.length > 3 ? (
                        <Carousel cols={3} rows={1} gap={10} loop showDots autoplay={5000}>
                          {
                            solutionsList.map((data, index) => {
                              return (
                                  <Carousel.Item key={index}>
                                    {/*<Grid item key={index} xs={12} sm={6} md={4}  >*/}
                                      <div>
                                      <CardThirdPartyEntity data={
                                        {
                                          url: `${data.externalUrl}`,
                                          image: `${data.imageUrl}`,
                                          organizationTag: `${data.title}`,
                                          organizationName: `${data.companyName}`,
                                          organizationLogo: `${data.companyLogo}`,
                                          organizationQuotes: `${data.description}`
                                        }
                                      }/>
                                      </div>
                                    {/*</Grid>*/}
                                    <div style={{marginTop: 4}}/>
                                  </Carousel.Item>

                              )
                            })
                          }
                        </Carousel>
                    ) : (
                        <Grid container spacing={3}>
                          {
                            solutionsList.map((data, index) => {
                              return (
                                  <Grid item key={index} xs={12} sm={6} md={4}  >
                                    <Grid >
                                      <CardThirdPartyEntity data={
                                        {
                                          url: `${data.externalUrl}`,
                                          image: `${data.imageUrl}`,
                                          organizationTag: `${data.title}`,
                                          organizationName: `${data.companyName}`,
                                          organizationLogo: `${data.companyLogo}`,
                                          organizationQuotes: `${data.description}`
                                        }
                                      }/>
                                    </Grid>
                                  </Grid>
                              )
                            })
                          }
                        </Grid>
                    )
                  }
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openDialogbottom}
          onClose={this.handleDailogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.userCardInfo.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <Typography  noWrap>
                      {'Desc:\t'+this.state.userCardInfo.desc}
                 </Typography>

                 <Typography   noWrap>
                      {'Due:\t'+this.state.userCardInfo.due.split('T')[0]}
                 </Typography>
          </DialogContentText>
          {retrievePopUpAvatar(this.state.getAllAvatarCardMembers)}
          </DialogContent>
          <DialogActions>
          <Typography  noWrap>
                      {'Current State: \t'+this.state.userCardInfoLane}
                 </Typography>
            <Button onClick={e=>this.handleMoveLaneClick(e)} color="primary" style={{ display: 'none' }}>
              Move task
          </Button>
          <StyledMenuZ
                                   id="customized-menu"
                                   anchorEl={this.state._listPopUpAnchorEl}
                                   keepMounted
                                   open={Boolean(this.state._listPopUpAnchorEl)}
                                   onClose={this.handleMoveLaneClickClose}

                               >
                                   {renderLaneResultFilter()}
                               </StyledMenuZ>
            <Button onClick={this.handleDailogClose} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
        </Dialog>

        <Drawer anchor="bottom" open={this.state.bottom} onClose={this.toggleDrawer('bottom', false,this.state.userCardInfo)}>
        {fullList('bottom')}
      </Drawer>
      </div>
    );
  }
}

HomeLayoutHub.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(HomeLayoutHub);
