import React, { Component } from 'react';
import {fade, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import StoreService from "../shared/StoreService";
import SearchBox from '../layout/Widget/SearchBox';
import CardThirdPartyEntity from "./Widget/CardThirdPartyEntity";
import Card from "@material-ui/core/Card";
import Loader from "react-loader-spinner";

const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        position: 'sticky',
        background: fade("#673ab7", 0.07)
    },
    alignMiddle: {
        height:  '75%',
        margin: 'auto',
        display: 'block',

    },
    media: {
        height: 100,
    },
    alignLoader:{
        textAlign: 'center'
    }
});


class ToolsLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            tools: [],
            searchfield: '',
            error: '',
            isTools: false
        };

        this.params = {
            filteredTools: null
        };

        this.StoreService = new StoreService();
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        this.getToolsInfo();
    }

    getToolsInfo() {
        console.log('Retrieving tools data..');
        this.StoreService
            .getToolsData()
            .then(res => res.data)
            .then(
                response => {
                    this.setState({
                        tools: response,
                        isTools: true

                    });
                },
                error => {
                    this.setState({
                        error
                    });
                }
            )
    };

    onSearchChange = (event) => {
        this.setState({
            searchfield : event.target.value
        });
    };


    render() {
        const { classes } = this.props;
        const { tools, searchfield } = this.state;

        const filteredSolution = this.state.tools.filter(tools => {
            return tools.title.toLowerCase().includes(searchfield.toLowerCase());
        });

        return (!tools.length) ? <div className={classes.alignLoader}><Loader type="ThreeDots" color="#3f51b5" height={80} width={80} /></div>  : (

            <div className={classes.root}>
                <Grid container  direction="row" justify="center" alignItems="center" spacing={4}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <div className={classes.alignMiddle}>
                              <SearchBox searchChange={this.onSearchChange} searchField={searchfield} />
                            </div>
                        </Paper>

                    </Grid>
                    {
                        filteredSolution.map((data, index) => {
                        return (<Grid item xs={12} sm={6} md={3} key={index}>
                            <Card>
                                <CardThirdPartyEntity data={
                                    {
                                        url: `${data.externalUrl}`,
                                        image: `${data.imageUrl}`,
                                        organizationTag: `${data.title}`,
                                        organizationName: `${data.companyName}`,
                                        organizationLogo: `${data.companyLogo}`,
                                        organizationQuotes: `${data.description}`
                                    }
                                }  isTools={this.state.isTools} />
                            </Card>
                        </Grid>)
                    })
                    }
                </Grid>
            </div>

        );

    }



}

export default withStyles(useStyles)(ToolsLayout);
