import React, { Component } from 'react';
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Board from 'react-trello';
import CardsService from '../../shared/cardService';
import boardsService from '../../shared/boardsService';
import Loader from 'react-loader-spinner';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import "../style.css"
import CustomCardForm from "../CustomCardForm";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
/*import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';*/
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { TextValidator } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LabelIcon from '@material-ui/icons/Label';
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Tooltip from '@material-ui/core/Tooltip';



function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    rootGrid: {
        flexGrow: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }, heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '55.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    taskWidth: {
        width: '100%'
    },
    inline: {
        display: "inline"
    },
    tableWrapper: {
        maxHeight: 240,
        overflow: "auto"
    },
    descriptionIconLayout1:{
        margin:'0 0 222% 0 !important'
    },
    descriptionLayout:{
        display: 'flex',
        width: '100%'
    },


});



const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ExpansionPanel = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        borderRadius: "5px",
        "&:not(:last-child)": {
            borderBottom: 0
        },

        "&:before": {
            display: "none"
        },
        "&$expanded": {
            margin: "auto"
        }
    },
    expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: "rgba(0,0,0,0)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        borderRadius: "5px",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56
        }
    },
    content: {
        "&$expanded": {
            margin: "12px 0"
        }
    },
    expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiExpansionPanelDetails);

class Supportboards extends Component {
    constructor(props) {
        super(props);
        console.log('Supportboards.js Call -- constructor');
        console.log('PROPS  TOKEN---> ' + this.props.data.token);
        console.log('PROPS  TOKEN---> ' + this.props.data.key);
        this.state = {
            cards: null,
            isLoaded: true,
            boards: [],
            value: 0,
            laneId: '',
            open: false,
            openCardInfoContent: null,
            cardCommentsInstance: [],
            listAvailableCommentsFlag: false,
            cardCommentsCreator:'',
            cardCommentsCreatorInitial:''
        };
          debugger;
        this.boardsService = new boardsService();
        this.CardsService = new CardsService();
    }

    data = {
        token: this.props.data.token,
        key: this.props.data.key,
        boardid: this.props.board,
        ObjectResponse: ''
    }

    /*FETCHES TRELLO BOARD NAVIGATION*/
    fetchTrelloBoards = () => {
        //  //  debugger;;
        this.boardsService
            .GetTrelloBoardNavigation(this.props.data)
            .then(res => res.data)
            .then(
                response => {
                    //  debugger;;
                    this.data.ObjectResponse = response;
                    for (var i = 0; i < response.length; ++i) {
                        if (response[i].name === "Technical Support") {
                            console.log(response[i]);
                            this.fetchTrelloCardsPerLane(response, response[i].id);
                            let obj = {
                                token: this.props.data.token,
                                key: this.props.data.key,
                                boardId: response[i].id
                            };

                            this.CardsService.GetAllLanesPerboard(obj)
                                .then(res => {
                                    for (var i = 0; i < res.data.length; ++i) {
                                        if (res.data[i].name.toString().toLowerCase().trim() === "backlog") {
                                            this.setState({
                                                laneId: res.data[i].id
                                            })
                                        }
                                    }
                                    console.log(JSON.stringify('LANES ---------------> ' + JSON.stringify(res)));
                                });
                        }
                    }


                },
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    handleChange = (event, value) => {
        //  debugger;;
        this.fetchTrelloCardsPerLane('', value);

    };



    handleClickOpen = (laneInfo, cardId) => {
        debugger;
        this.setState({
            commentCardType: true,
            cardCommentsInstance: [],
            listAvailableCommentsFlag: false,
            cardCommentsCreator:'',
            cardCommentsCreatorInitial: ''
        });
        let openCardInfoHolder = { cardId: '', cardTitle: '', cardDesc: '', cardContent: '', openCard: false, cardLabel: '', cardStyle: '', cardBadgeDueDate: '', cardMembers: [], cardBadgeCommentSize: 0 };
        if (laneInfo.lanes.length > 0) {
            laneInfo.lanes.forEach(function (entry) {
                if (entry.cards.length === 1) {
                    console.log('Size is 1' + entry);
                    if (cardId === entry.cards[0].id) {
                        openCardInfoHolder.cardId = entry.cards[0].id;
                        openCardInfoHolder.cardTitle = entry.cards[0].title;
                        openCardInfoHolder.cardDesc = entry.cards[0].description;
                        openCardInfoHolder.cardContent = entry.cards[0];
                        openCardInfoHolder.openCard = true;
                        openCardInfoHolder.cardLabel = (entry.cards[0].labels !== null)?(entry.cards[0].labels.length > 0 ?entry.cards[0].labels[0].name : ''):'';
                        openCardInfoHolder.cardStyle = (entry.cards[0].labels !== null)?(entry.cards[0].labels.length > 0 ?entry.cards[0].labels[0].color : 'white'):'white';
                        openCardInfoHolder.cardBadgeDueDate = typeof entry.cards[0].badges.due !== 'undefined' ? entry.cards[0].badges.due : '';
                        openCardInfoHolder.cardMembers = entry.cards[0].members;
                        openCardInfoHolder.cardBadgeCommentSize = entry.cards[0].badges.comments;
                    }
                } else if (entry.cards.length > 1) {
                    entry.cards.forEach(function (info) {
                        if (cardId === info.id) {
                            openCardInfoHolder.cardId = info.id;
                            openCardInfoHolder.cardTitle = info.title;
                            openCardInfoHolder.cardDesc = info.description;
                            openCardInfoHolder.cardContent = info;
                            openCardInfoHolder.openCard = true;
                            openCardInfoHolder.cardLabel = info.labels !== null ? (info.labels.length > 0 ? info.labels[0].name : ''):'';
                            openCardInfoHolder.cardStyle = info.labels !== null? (info.labels.length > 0 ? info.labels[0].color : 'white'):'white';
                            openCardInfoHolder.cardBadgeDueDate = typeof info.badges.due !== 'undefined' ? info.badges.due : '';
                            openCardInfoHolder.cardMembers = info.members;
                            openCardInfoHolder.cardBadgeCommentSize = info.badges.comments;
                        }
                    });
                }
            });
            (openCardInfoHolder.cardId !== '' && openCardInfoHolder.cardContent !== '') ?
                this.setState({ open: true, openCardInfoContent: openCardInfoHolder }) : this.setState({ open: true, openCardInfoContent: openCardInfoHolder });
            if (openCardInfoHolder.openCard && openCardInfoHolder.cardBadgeCommentSize >= 0) {

                this.boardsService
                    .GetUserCardCommentNavigation(cardId, this.props.data)
                    .then(res => res.data)
                    .then(
                        response => {
                            const responseCardCommentNavigation = response;
                            debugger;
                            let responseCardComment = [], commentCardType = false;
                            for (var i = 0; i < responseCardCommentNavigation.length; ++i) {
                                if (typeof responseCardCommentNavigation[i].data.text !== 'undefined' && responseCardCommentNavigation[i].type === 'commentCard') {
                                    responseCardComment.push(responseCardCommentNavigation[i]);
                                    commentCardType = true;
                                }
                            }
                            this.setState({
                                commentCardType: commentCardType,
                                cardCommentsInstance: responseCardComment,
                                listAvailableCommentsFlag: true,
                                cardCommentsCreator:(responseCardComment.length > 0)?responseCardComment[responseCardComment.length-1].memberCreator.fullName:'',
                                cardCommentsCreatorInitial:(responseCardComment.length > 0)? responseCardComment[responseCardComment.length-1].memberCreator.initials:''
                            });
                        },
                        error => {
                            debugger;
                            this.setState({
                                listAvailableCommentsFlag: false,
                                error
                            });
                        }
                    );
            }

        }


    };
    handleClose = () => {

        this.setState({ open: false });
    };

    fetchTrelloCardsPerLane = (carry, boardId) => {
        this.setState({
            cards: null,
            isLoaded: true
        });
        this.CardsService
            .GetAllTrelloCardsPerLane(this.data, boardId)
            .then(res => {
                //  debugger;;
                let userBoards;
                userBoards = (carry !== '') ? carry : this.data.ObjectResponse;
                this.setState({
                    cards: res.data,
                    isLoaded: false,
                    boards: userBoards,
                    value: boardId

                });
            },
                error => {
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            );
    }
    parseJwt = token => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    componentDidMount() {
        this.fetchTrelloBoards();
    }

    render() {
        //  debugger;;
        const classes = this.props;
        console.log('Board.js Call --render');
        console.log("BOOLEAN LOGGER --->" + this.state.isLoaded)
        let data;
        if (this.state.boards.length !== 0) {
            //  debugger;;
            this.state.boards.map((board, index) => <Tab label={board.name} key={index} value={board.id} />);
        }
        (this.state.cards === null) ? data = { "lanes": [] } : data = this.state.cards;



        /** list all comments */
        const listAvailableComments = (res, flag) => {

            if (res.length === 0) {

                if (flag) {
                    return <List className={classes.taskWidth}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary="No comments have been added."
                            />
                        </ListItem>
                    </List>
                } else {
                    return <List className={classes.taskWidth}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary=":) just busy fetching comments ..."
                            />
                        </ListItem>
                    </List>
                }


            } else {
                return (
                    <List className={classes.taskWidth}>
                        {res.map((sectionId, index) => {
                            return (
                                //button onClick={this.toggleDrawer('bottom', true,sectionId)}
                                <ListItem alignItems={"flex-start"} key={index} >
                                    <ListItemAvatar>
                                        <Chip
                                            avatar={<Tooltip title={sectionId.memberCreator.fullName}>
                                            <Avatar>{sectionId.memberCreator.initials}</Avatar>
                                            </Tooltip>}  
                                            onClick={()=>{}}                                         
                                            
                                            variant="outlined"
                                            color="primary"
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                component={"h2"}
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                                style={{ fontSize: "15px" }}
                                            >
                                                {" " + sectionId.data.list.name}
                                            </Typography>
                                        </React.Fragment>}
                                        secondary={
                                            <React.Fragment>
                                                {
                                                    sectionId.data.text ? (
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                className={classes.inline}
                                                                color="textPrimary"
                                                                style={{ fontSize: "13px", lineHeight: 1 }}
                                                            >
                                                                Description -
                                        </Typography>
                                                            {" " + sectionId.data.text}
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    className={classes.inline}
                                                                    color="textPrimary"
                                                                    style={{ fontSize: "13px", lineHeight: 1 }}
                                                                >
                                                                    Description -
                                        </Typography>
                                                                {" " + "None"}
                                                            </React.Fragment>
                                                        )
                                                }
                                            </React.Fragment>
                                        }
                                    />

                                </ListItem>

                            );
                        })}
                    </List>
                );
            }

        };

        const showCommentCreatorAvatar = (cardCommentsCreatorInitial) => {
        return (
             (cardCommentsCreatorInitial !== '')?
             <Tooltip title={this.state.cardCommentsCreator}>
            <Avatar style={{backgroundColor: 'blue',
                                            color: 'white'}}>
                                            {cardCommentsCreatorInitial}
                                            </Avatar>
                                            </Tooltip>:''
        );
        }

        const showCommentCreatorCategory= (openCardInfoContent) => {
            return (
                (openCardInfoContent !== null)?
            <MenuItem
                                            value={(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardLabel : ''} title={(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardLabel : ''} name={(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardContent : ''} data-my-value={JSON.stringify((this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardContent : {})}>
                                            <LabelIcon style={{ color: (this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardStyle : 'white' }} />
                                            &nbsp; &nbsp;{(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardLabel : ''}
                                        </MenuItem>:''
            )
        }

        /*DRAG CARD BETWEEN  LANES*/
        const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
            const objMoveCard = {
                key: this.props.data.key,
                token: this.data.token,
                cardId: cardId,
                laneId: targetLaneId,
                position: "",
            }
            this.moveCardToLane(objMoveCard);
            // this.fetchTrelloCardsPerLane(); //refresh board
            console.log('drag ended')
            console.log('CARD ID - ' + cardId);
            console.log('SOURCE LANE ID - ' + sourceLaneId)
            console.log('TARGET LANE ID - ' + targetLaneId)
        }

        const onCardAdd = (card, laneId) => {
            const objAddCard = {
                key: this.props.data.key,
                token: this.data.token,
                laneId: this.state.laneId,
                cardId: card.id,
                cardName: card.title,
                cardDesc: card.description
            }
            this.fetchTrelloCardsPerLane();
            console.log(objAddCard);
            this.addCardToLane(objAddCard);
        }
        const onCardDelete = (card, laneId) => {
            const objDeleteCard = {
                cardId: card,
                closed: true,
                key: this.props.data.key,
                laneId: laneId,
                token: this.data.token,
            }
            console.log(objDeleteCard);
            this.DeleteCard(objDeleteCard);
        }
        const onCardClick = (cardId, metadata, laneId) => {
            //  debugger;;
            console.log(cardId);
            console.log(metadata);
            console.log(laneId);

        }
        /*MOVES CARD TO LANE*/
        this.moveCardToLane = (objMoveCard) => {
            //  debugger;;
            this.CardsService
                .MoveCardToLane(objMoveCard)
                .then(res => {
                    toast.notify("Card successfully moved", {
                        duration: 3000
                    });
                    //  this.notifier.openSnackbar({ message: 'Card successfully moved' });
                    console.log("<span style='color:green'>Card successfully moved");
                })
                .catch(error => {
                    toast.notify("Error moving card", {
                        duration: 3000
                    });
                });
        }
        /*ADD CARD TO LANE*/
        this.addCardToLane = (objAddCard) => {
            debugger;;
            this.CardsService
                .addCardToLane(objAddCard)
                .then(res => {
                    console.log('MY SUCCESS DATA' + JSON.stringify(res.data));
                    this.setState({ 'cardId': res.data.cardId })

                    this.fetchTrelloBoards();
                    toast.notify("Card successfully added", {
                        duration: 800
                    });


                    const addMember = {
                        key: this.props.data.key,
                        token: this.props.data.token,
                        cardId: this.state.cardId,
                        employeeNum: this.state.employeeNo,
                        idMember: res.data.id,
                        samaccountName: this.state.samaccountName

                    };
                    console.log('ADD MEMBER OBJECT ------- ' + JSON.stringify(addMember))
                    this.CardsService.AddMemberToCard(addMember).then(res => res.data).then(
                        response => { },
                        error => {
                            this.setState({
                                isLoaded: false
                            });

                            toast.notify("Error retrieving data" + error, {
                                duration: 3000
                            });
                        }
                    );

                })
                .catch(error => {
                    toast.notify("Error adding card", {
                        duration: 3000
                    });
                });
        }
        /*DELETE CARD FROM LANE*/
        this.DeleteCard = (objDeleteCard) => {
            //  debugger;;
            this.CardsService
                .DeleteCard(objDeleteCard)
                .then(res => {
                    toast.notify("Card successfully deleted", {
                        duration: 3000
                    });
                    //   this.notifier.openSnackbar({ message: 'Card successfully deleted' });
                    console.log("Delete Card Successfully ");
                })
                .catch(error => {
                    toast.notify("Error deleting card", {
                        duration: 3000
                    });
                });
        }
        let userLog = '', userLogIn = '';
        if (typeof localStorage.getItem('mmih_auth_token') !== "undefined") {
            userLog = this.parseJwt(localStorage.getItem('mmih_auth_token')).sub.displayName;
            userLogIn = this.parseJwt(localStorage.getItem('mmih_auth_token'));
        }


        return (
            <div>
                <div className='MyBoards' >

                    <span className="TechSupportHeading">  Technical Support Board </span> <br />
                    Log any technical queries on this board.
                  <div className="AlignLoader">
                        <Loader type="ThreeDots" color="#3f51b5" height={80} width={80} visible={this.state.isLoaded} />
                    </div>


                    <Board data={data}
                        cardDraggable={false}
                        style={{ backgroundColor: '#FAFAFA' }}
                        id="EditableBoard1"
                        handleDragEnd={handleDragEnd}
                        onCardAdd={onCardAdd}
                        // onCardDelete = {onCardDelete}
                        onCardClick={this.handleClickOpen.bind(this, data)}
                        editable
                        hideCardDeleteIcon
                        collapsibleLanes
                        laneDraggable={false}
                        components={{ NewCardForm: CustomCardForm }}

                    />

                </div>
                <Dialog disableBackdropClick disableEscapeKeyDown onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open} fullWidth
                    maxWidth={'md'} modal="true">
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardTitle : ''}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div className={classes.rootGrid}>
                            <Grid container  >
                                <Grid container spacing={1} alignItems="flex-end" className={classes.taskWidth} >
                                <Grid item xs={1}>
                                        {(this.state.openCardInfoContent !== null)?'Task Category':''}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {
                                            showCommentCreatorCategory(this.state.openCardInfoContent)
                                        }

                                    </Grid>
                                    <Grid item xs={1}>
                                        {(this.state.cardCommentsCreatorInitial!== '')?'Created By':''} 
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            showCommentCreatorAvatar(this.state.cardCommentsCreatorInitial)
                                        }                              
                                            
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                                <Grid container spacing={1} alignItems="flex-end" className={classes.taskWidth} >

                                    <Grid item xs={1}>                                    
                                        <SubjectIcon style={{margin: '0 0 80% 0'}}/>                                        
                                    </Grid>
                                    <Grid item xs={10}>
                                       
                                        <TextField
                                            name='cardDesc'
                                            id="cardDesc"
                                            label="Description"
                                            placeholder="Enter task description"
                                            variant="filled"
                                            multiline
                                            rows={8}
                                            
                                            style={{display: 'flex',
                                            width: '100%'}}
                                            readonly
                                            value={(this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.cardDesc : ''}
                                        />
                                        
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                                <Grid container spacing={1} alignItems="flex-end" className={classes.taskWidth} >
                                <Grid item xs={12}></Grid>
                                    <Grid item xs={12}>
                                        <ExpansionPanel >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                <Typography variant="inherit" component="h3">
                                                    Comments
                                           </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.tableWrapper}>
                                                {listAvailableComments(this.state.cardCommentsInstance, this.state.listAvailableCommentsFlag)}
                                            </ExpansionPanelDetails>
                                            <Divider />

                                        </ExpansionPanel>

                                    </Grid>
                                </Grid>
                            </Grid>


                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={this.handleClose} color="primary">
                            Close
          </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default withStyles(styles)(Supportboards);
