import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "@material-ui/core/Button";
import toast from "toasted-notes";
import CardsService from "../shared/cardService";
import DummyAvator from '../avatar/smartfox@640.png';
import Avatar from "@material-ui/core/Avatar";
import MenuItem from '@material-ui/core/MenuItem';
import LabelIcon from '@material-ui/icons/Label';
import Chip from '@material-ui/core/Chip';
import {red, yellow} from "@material-ui/core/colors";
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import Loader from "react-loader-spinner";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import SubjectIcon from '@material-ui/icons/Subject';
import moment from 'moment';

import ShortTextIcon from '@material-ui/icons/ShortText';

const red300 = red['500'];

const style = {
    right: 0,
    fontSize: '12px',
    color: red300,
    position: 'absolute',
    marginTop: '-25px',
}

const styles = theme => ({

    root: {
        margin: 0,
        padding: theme.spacing(15),

    },


    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '9px',
        boxsizing: 'border-box'
    },


    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },


    dense: {
        marginTop: 19
    },
    menu: {
        width: 200,
    },
    avatar: {
        margin: 10,
    },
    formControl: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        width: 450
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addMember: {
        marginLeft: "39px",
        marginTop: "15px",
        // marginRight: theme.spacing(2),
        width: 510
    },


});


class CustomizedDialogs extends React.Component {
    constructor(props) {
        super(props);
        // console.log('MEMBER DATA ' + this.props.data.cardContent.members)
        this.state = {
            title: '',
            openDialog: props.customizedDialogState,
            items: props.boardMembers,
            startDate: '',
            dueDate: '',
            cardDesc: '',
            background: '',
            taskName: '',
            TaskSelection: '',
            taskId: '',
            TaskData: [],
            TaskTypeState: '',
            currentBoardMembers: this.props.AllboardMembers,
            currentCardMembers: this.props.data.cardContent.members,
            employeeNo: props.data.employeeNo,
            samaccountName: props.data.samaccountName,
            isLoaded: false,
            CheckedOffice365: true,
            HideShowOffice365Date: '',
            IcalUId: '',
            EventId: '',
            CheckboxReadOnly: false,
            defaultValueAutoCompleteValue: []
        }

        this.handleChangeTaskTypes = this.handleChangeTaskTypes.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.CardsService = new CardsService();
        this.handleAddMember = this.handleAddMember.bind(this);

        this.handleDeleteMember = this.handleDeleteMember.bind(this);
        this.GetAllLabels();

        // console.log(this.props.members)


    }


    AllBoardMembers = this.props.AllboardMembers;
    AllCardMembers = this.props.data.cardContent;

    renderTaskTypesOptions() {
        return this.state.TaskData.map((dt, i) => {
            return (
                <MenuItem
                    key={i}
                    value={dt.name} title={dt.description} name={dt} data-my-value={JSON.stringify(dt)}>
                    <LabelIcon style={{color: dt.color}}/>
                    &nbsp; {dt.name}
                </MenuItem>

            );
        });
    }


    GetAllLabels() {
        this.CardsService
            .GetAllCardLabels()
            .then(res => {
                this.setState({
                    //   TaskTypeState: 'SELECT A TASK TYPE',
                    TaskData: res.data
                });
            })
            .catch(error => {
                toast.notify("Error retrieving data", {
                    duration: 3000
                });
            });
    }


    handleChangeTaskTypes(event) {
        var inputDesc = event.view.document.getElementById('cardDesc').value;
        var inputDueDate = event.view.document.getElementById('dueDate').value;
        var inputStartDate = event.view.document.getElementById('startDate').value;
        var inputSubject = event.view.document.getElementById('subject').value;
        var obj = JSON.parse(event.currentTarget.dataset.myValue);


        this.setState({
            taskId: obj.id,
            TaskTypeState: event.target.value,
            cardDesc: inputDesc,
            dueDate: inputDueDate,
            startDate: inputStartDate,
            title: inputSubject,

        })
    }


    handleSubmitData = (event) => {
        let taskId;
        let inputVal = document.getElementById('cardDesc').value;
        event.preventDefault();
        const data = new FormData(event.target);
        if (this.state.taskId == "") {
            taskId = "0"
        } else {
            taskId = this.state.taskId;
        }

        let eventId, icalUid;

        if (this.props.data.cardContent.calendarEvents !== null) {
            eventId = this.props.data.cardContent.calendarEvents.eventId;
            icalUid = this.props.data.cardContent.calendarEvents.icalUid;
        } else {
            eventId = "";
        }

        const CardCalendarData = {
            calendarEvent: this.state.CheckedOffice365,
            attendeeList: [],
            bodyContent: data.get('cardDesc'),
            calendarLocationName: "",
            cardId: this.props.data.cardId,
            eventId: eventId,
            icalUid: icalUid,
            startDate: data.get('startDate'),
            endDate: data.get('dueDate'),
            subject: data.get('subject'),
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName,
            cardDTO: {
                cardDesc: data.get('cardDesc'),
                cardId: this.props.data.cardId,
                cardName: data.get('subject'),
                dueDate: data.get('dueDate'),
                key: this.props.data.key,
                taskId: parseInt(taskId),
                token: this.props.data.token

            },
            body: {
                content: data.get('cardDesc'),
                contentType: 'HTML'
            }
        }


        console.log("------CalendarObj TouF ---->" + JSON.stringify(CardCalendarData))

        let label;
        if (this.props.data.cardContent.labels !== null) {
            label = this.props.data.cardContent.labels[0].name
        } else {
            label = ""
        }


        if ((this.state.CheckedOffice365 === false) && (data.get('dueDate').trim() === this.props.data.dueDate.trim())
            && (data.get('cardDesc').trim() === this.props.data.cardDesc.trim()) &&
            (data.get('taskTypes').trim() === label.trim()) && (JSON.stringify(this.state.currentCardMembers) === JSON.stringify(this.props.data.cardContent.members))) {
            // this.setState({ open: false });
            toast.notify("Successfully updated", {
                duration: 3000
            });
            this.setState({openDialog: false});
        } else {
            this.setState({
                isLoaded: true,
                cardDesc: data.get('cardDesc')
            });
            this.CardsService.createOffice365Event(CardCalendarData)
                .then(res => {
                    console.log("CREATE EVENT 365" + JSON.stringify(res));

                    if (res.data.success === true) {
                        toast.notify(res.data.message, {
                            duration: 3000
                        });
                        this.setState({
                            isLoaded: false,
                            openDialog: false
                        });
                        this.props.parentCallbackRefreshBoard({
                            boardId: this.props.data.boardId,
                            HideShowOffice365Date: this.state.HideShowOffice365Date,
                            addCardToLaneFlag: false,
                            addCardToLaneByCardId: '',
                            addCardToLaneByLaneId: ''
                        });

                        //this.props.parentCallbackRefreshBoard(this.props.data.boardId, this.state.HideShowOffice365Date);
                    }
                }).catch(error => {
                this.setState({
                    "isLoaded": false,
                });
                toast.notify("Error updating card", {
                    "duration": 3000
                });
            });
        }
    }


    handleClose() {
        debugger;
        this.setState({
            isLoaded: false,
            CheckboxReadOnly: true,
            OpenDialog: false,
            tabClick: false
        })

        let label;
        if (this.props.data.cardContent.labels !== null) {
            label = this.props.data.cardContent.labels[0].name
        } else {
            label = ""
        }

        if ((this.state.dueDate.trim() === this.props.data.dueDate.trim()) && (this.state.cardDesc.trim() === this.props.data.cardDesc.trim()) && (label === this.state.TaskTypeState.trim())) {
            this.props.parentCloseDialogue(this.state.tabClick);
            //   this.setState({openDialog: false,tabClick: false});
        } else {
            this.setState({openDialog: false});
            this.props.parentCallbackRefreshBoard({
                boardId: this.props.data.boardId,
                addCardToLaneFlag: false,
                addCardToLaneByCardId: '',
                addCardToLaneByLaneId: ''
            });

        }
    }


    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        //    (nextProps.data.cardDesc !== this.props.data.cardDesc) ? console.log("initial AWS  === " + this.props.data.cardDesc) : console.log("old AWS  === " + this.props.data.cardDesc);


        let TaskTypename
        if (nextProps.data.cardContent.labels !== null) {
            TaskTypename = nextProps.data.cardContent.labels[0].name;
        } else {
            TaskTypename = "SELECT A TASK TYPE";
        }

        //check exist on 0ffice 365

        if (nextProps.data.cardContent.calendarEvents !== null) {

            this.setState({
                startDate: nextProps.data.cardContent.calendarEvents.eventStartDate,
                endDate: nextProps.data.cardContent.calendarEvents.eventEndDate,
                CheckedOffice365: true,
                HideShowOffice365Date: 'block',
                icalUid: nextProps.data.cardContent.calendarEvents.icalUid,
                EventId: nextProps.data.cardContent.calendarEvents.eventId,
                CheckboxReadOnly: true
            })
        } else {
            if (nextProps.data.dueDate !== "Invalid date") {
                this.setState({
                    HideShowOffice365Date: 'none',
                    startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    CheckedOffice365: false,
                    isLoaded: false,
                    CheckboxReadOnly: false
                })
            } else {
                this.setState({
                    startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    CheckedOffice365: true,
                    HideShowOffice365Date: 'block',
                    CheckboxReadOnly: false
                })
            }
        }

        this.setState({
            //   open: props.customizedDialogState,
            items: this.props.boardMembers,
            background: '',
            taskName: '',
            TaskSelection: '',
            TaskData: this.state.TaskData,
            TaskTypeState: TaskTypename,
            currentBoardMembers: nextProps.AllboardMembers,
            currentCardMembers: nextProps.data.cardContent.members,
            employeeNo: nextProps.data.employeeNo,
            samaccountName: nextProps.data.samaccountName,
            isLoaded: false,
            openDialog: nextProps.customizedDialogState,
            cardDesc: nextProps.data.cardDesc,
            dueDate: nextProps.data.dueDate,
            title: nextProps.data.cardContent.title

        });


    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isEndDateGreaterStartDateCheck');
        ValidatorForm.removeValidationRule('isTaskTypeSelected');
        ValidatorForm.removeValidationRule('isDueDateCaptured');
        ValidatorForm.removeValidationRule('isStartDateCaptured');
        ValidatorForm.removeValidationRule('isSubject');
        debugger;
        this.setState({
            OpenDialog: false
        });
    }

     defaultValueAutoComplete() {
        debugger
       var arr = [];
        for (var item = 0; item < this.state.currentBoardMembers.length; ++item) {
            for (var i = 0; i < this.state.currentCardMembers.length; ++i) {
                if (this.state.currentBoardMembers[item].id === this.state.currentCardMembers[i].id) {
                    arr.push(this.state.currentBoardMembers[item]);

                }
            }
       }
        return arr;
    }

    componentDidMount() {
        this.CardsService.GetCalendarO365(this.props.data).then(res => res.data)
            .then(
                response => {
                    sessionStorage.setItem("mmih_accessToken", response.user_access_token);
                    localStorage.setItem('mmih_accessToken', response.user_access_token);
                },
                error => {
                    toast.notify("Error retrieving data" + error, {
                        duration: 3000
                    });
                }
            );

        if ((this.props.data.cardContent.calendarEvents !== null)) {
            this.setState({
                HideShowOffice365Date: 'block',
                icalUid: this.props.data.cardContent.calendarEvents.icalUid,
                startDate: this.props.data.cardContent.calendarEvents.eventStartDate,
                dueDate: this.props.data.cardContent.calendarEvents.eventEndDate,
                EventId: this.props.data.cardContent.calendarEvents.eventId,
                CheckedOffice365: true,
                isLoaded: false,
                CheckboxReadOnly: true,
                title: this.props.data.cardContent.title
            })

        } else {

            if (this.props.data.dueDate == "Invalid date") {
                this.setState({
                    HideShowOffice365Date: 'block',
                    startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    dueDate: this.state.dueDate.toString(),
                    CheckedOffice365: true,
                    isLoaded: false,
                    CheckboxReadOnly: false,
                    title: this.props.data.cardContent.title
                })
            } else {
                this.setState({
                    HideShowOffice365Date: 'none',
                    startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    dueDate: this.state.dueDate.toString(),
                    CheckedOffice365: false,
                    isLoaded: false,
                    CheckboxReadOnly: false,
                    title: this.props.data.cardContent.title
                })
            }
        }


        //Validate
        ValidatorForm.addValidationRule('isStartDateCaptured', (value) => {
                if ((value === 'Invalid date') || (value === '') || (value === undefined)) {
                    return false;
                }
                return true;
            }
        );

        ValidatorForm.addValidationRule('isStartDateCheck', (value) => {
                if (value > this.state.dueDate) {
                    return false;
                }
                return true;
            }
        );

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });


        //Validate  Due
        ValidatorForm.addValidationRule('isDueDateCaptured', (value) => {
            if ((value === 'Invalid date') || (value === '') || (value === undefined)) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isEndDateGreaterStartDateCheck', (value) => {
            // alert('Start Date ' + this.state.startDate + ' ' +  'End Date' +  value )
            let isafter = moment(value).isAfter(this.state.startDate);
            if (isafter === false) {
                return false;
            }
            return true;


        });

        //Validate  Task Type
        ValidatorForm.addValidationRule('isTaskTypeSelected', (value) => {
            if (value === 'SELECT A TASK TYPE') {
                return false;
            }
            return true;
        });


        //Validate  Task Type
        ValidatorForm.addValidationRule('isSubject', (value) => {

            if (value === '') {
                return false;
            }
            return true;
        });


        this.CardsService.retrieveCardMembers({
            cardId: this.props.data.cardId,
            key: this.props.data.key,
            token: this.props.data.token
        }).then(res => {
            this.setState({currentCardMembers: res.data});
        }).catch(error => {
            alert('error' + error);

        });


        let TaskTypename
        if (this.props.data.cardContent.labels !== null) {
            TaskTypename = this.props.data.cardContent.labels[0].name;
        } else {
            TaskTypename = "SELECT A TASK TYPE";
        }
        this.setState({
            dueDate: this.props.data.dueDate.toString(),
            cardDesc: this.props.data.cardDesc.toString(),
            TaskTypeState: TaskTypename
        });

    }

    handleAddSubject = (event) => {
        this.setState({title: event.target.value});
    }


    handleAddMember = (e, chipToAddMember) => {

        let objAddCard = {
            key: 'ObjCardToAdd.key',
            token: 'ObjCardToAdd.token',
            idMember: 'ObjCardToAdd.idMember',
            cardId: 'ObjCardToAdd.cardId',
            samaccountName: 'ObjCardToAdd.employeeNum',
            employeeNum: 'ObjCardToAdd.employeeNo'

        }

        let AllBoardMembers = this.props.AllboardMembers;
        if (e.target.innerText === '') return;
        for (var i = 0; i < AllBoardMembers.length; i++) {
            if (e.target.innerText === AllBoardMembers[i].fullName) {
                objAddCard.key = this.props.data.key;
                objAddCard.token = this.props.data.token;
                objAddCard.idMember = AllBoardMembers[i].id;
                objAddCard.cardId = this.props.data.cardId;
                objAddCard.employeeNum = this.state.employeeNo;
                objAddCard.samaccountName = this.state.samaccountName;
                break;
            }
        }
        if (objAddCard.key === 'ObjCardToAdd.key' || objAddCard.idMember === 'ObjCardToAdd.idMember' || objAddCard.cardId === 'ObjCardToAdd.cardId' || this.state.currentCardMembers.find(function (element) {
            return element.id === objAddCard.idMember;
        })) {

            this.CardsService.retrieveCardMembers({
                cardId: this.props.data.cardId,
                key: this.props.data.key,
                token: this.props.data.token
            }).then(res => {
                this.setState({currentCardMembers: res.data});
            }).catch(error => {
                alert('error' + error);
            });
        } else {
            toast.notify('Adding member/s currently in progress', {
                duration: 3000
            });


            console.log('CARD MEMBER : ' + JSON.stringify(objAddCard));

            this.CardsService
                .AddMemberToCard(objAddCard)
                .then(res => {
                    this.CardsService.retrieveCardMembers({
                        cardId: this.props.data.cardId,
                        key: this.props.data.key,
                        token: this.props.data.token
                    }).then(res => {
                        this.setState({currentCardMembers: res.data});
                    }).catch(error => {
                        alert('error' + error);
                    });
                    //console.log(JSON.stringify(res.data[0].fullName))
                    toast.notify(res.data.message, {
                        duration: 3000
                    });

                })
                .catch(error => {
                    alert('error' + error);
                });
        }

    };
    handleDeleteMember = (e, chipToDelete, allChips) => {
        debugger;


        let objDeleteCard = {
            cardId: this.props.data.cardId,
            idMember: chipToDelete.id,
            key: this.props.data.key,
            token: this.props.data.token,
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName
        };
        toast.notify('Deleting member/s current in progress...', {
            duration: 3000
        });

        this.CardsService
            .removeMemberAssignedCard(objDeleteCard)
            .then(res => {
                //console.log(JSON.stringify(res.data[0].fullName))
                for (var i = 0; i < allChips.length; i++) {
                    if (allChips[i].id == chipToDelete.id)
                    {
                        allChips.splice(i, 1);
                    }
                }
                toast.notify(res.data.message, {
                    duration: 3000
                });
                this.CardsService.retrieveCardMembers({
                    cardId: this.props.data.cardId,
                    key: this.props.data.key,
                    token: this.props.data.token
                }).then(res => {
                    this.setState({currentCardMembers: res.data});
                })
                    .catch(error => {
                        alert('error' + error);
                    });
            })
            .catch(error => {
                toast.notify(error + " Member issue", {
                    duration: 3000
                });
            });


    };

    handleDueDateChange = (event) => {
        this.setState({dueDate: event.target.value});
    }


    handleStartDateChange = (event) => {
        this.setState({startDate: event.target.value});
    }


    render() {

        const {classes} = this.props;
        const DialogTitle = withStyles(styles)(props => {
            const {children, classes, onClose} = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2),
            },
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1),
            },
        }))(MuiDialogActions);





        const handleCheckedOffice365 = () => event => {

            this.setState({CheckedOffice365: event.target.checked});
            if (this.state.CheckedOffice365 === true) {
                this.setState({
                    startDate: moment('1800-01-01T00:00').format('YYYY-MM-DD[T]HH:mm'),
                    HideShowOffice365Date: 'none',
                    cardDesc: document.getElementById('cardDesc').value
                })

                // console.log('Start TIME -----' + this.state.startDate);
            } else {
                this.setState({
                    startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    HideShowOffice365Date: 'none',
                    cardDesc: document.getElementById('cardDesc').value
                })
                ValidatorForm.addValidationRule('isStartDateCaptured', (value) => {
                    if ((value === 'Invalid date') || (value === '') || (value === undefined)) {
                        return false;
                    }
                    return true;
                });

                //Add validation Rule
                ValidatorForm.addValidationRule('isStartDateCaptured', value => value);
                this.setState({HideShowOffice365Date: 'block'});
            }
        };

        const DefaultAvatar = (option) =>
            option.avatarHash === undefined ? DummyAvator : option.avatarUrl + '/170.png';
        debugger;
        return (


            <Dialog disableBackdropClick disableEscapeKeyDown onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title" open={this.state.openDialog} width={1200}>

                <DialogTitle style={{backgroundColor: yellow}} id="customized-dialog-title"
                             onClose={this.handleClose}>
                    <Grid container direction="row" alignItems="left">
                        <Grid item>
                            <Loader type="ThreeDots" color="#3f51b5" height={30} width={40}
                                    visible={this.state.isLoaded}/>
                        </Grid>
                        <Grid item>
                            &nbsp; Update Task
                        </Grid>
                    </Grid>

                </DialogTitle>
                <ValidatorForm onSubmit={this.handleSubmitData} onError={errors => console.log(errors)} ref="form"
                               id='id_ValidatorForm'>
                    <Grid container>

                        <Grid item xs={6}>
                            &nbsp;&nbsp;Add task to your calendar
                            <Checkbox disabled={this.state.CheckboxReadOnly}
                                      checked={this.state.CheckedOffice365}
                                      onChange={handleCheckedOffice365('CheckedOffice365')}
                                      value="checkedB"
                                      color="primary"
                                      inputProps={{
                                          'aria-label': 'secondary checkbox',
                                      }}/>
                        </Grid>
                        <Grid container spacing={2} alignItems="flex-end">

                            <Grid item xs={6} style={{display: this.state.HideShowOffice365Date}}>
                                <span className="star">*</span> <EventIcon
                                style={{marginRight: "2px", marginTop: "20px"}}/>
                                <TextValidator
                                    name='startDate'
                                    id="startDate"
                                    label="Start Date"
                                    type="datetime-local"
                                    onChange={this.handleStartDateChange}
                                    validators={['isStartDateCaptured', 'required']}
                                    errorMessages={['Start date is required']}
                                    value={this.state.startDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    visiability={this.state.CheckedOffice365.toString()}

                                />
                            </Grid>

                            <Grid item xs={6}>
                                <span className="star">*</span> <EventIcon
                                style={{marginRight: "2px", marginTop: "20px"}}/>
                                <TextValidator
                                    onChange={this.handleDueDateChange}
                                    validators={['isDueDateCaptured', 'isEndDateGreaterStartDateCheck', 'required']}
                                    errorMessages={['Due date is required.', 'Start date cannot be greater than end date.']}
                                    name='dueDate'
                                    id="dueDate"
                                    label="Due Date"
                                    type="datetime-local"
                                    value={this.state.dueDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}


                                />
                            </Grid>
                            <Grid item xs={12}> <br/>
                                <span className="star">*</span> <AssignmentIcon style={{marginRight: "2px"}}/>
                                <SelectValidator
                                    validators={['isTaskTypeSelected', 'required']}
                                    errorMessages={['Task type is required']}
                                    id="taskTypes"
                                    name="taskTypes"
                                    placeholder="Select task type"
                                    defaultValue={this.state.TaskTypeState}
                                    value={this.state.TaskTypeState}
                                    onChange={(e) => this.handleChangeTaskTypes(e)}
                                    className={classes.textField}
                                >
                                    <MenuItem value={this.state.TaskTypeState}>Select task type</MenuItem>
                                    {this.renderTaskTypesOptions()}


                                </SelectValidator>
                            </Grid>

                            <Grid item xs={12}>
                                <span className="star">*</span> <ShortTextIcon style={{marginRight: "2px"}}/>

                                <TextValidator
                                    validators={['isSubject', 'required']}
                                    errorMessages={['Subject is required']}
                                    name='subject'
                                    id="subject"
                                    label="Subject"
                                    placeholder="Enter subject"
                                    className={classes.textField}
                                    value={this.state.title}
                                    onChange={(evt) => this.handleAddSubject(evt)}

                                />
                            </Grid>


                            <Grid item xs={12}> <br/>
                                <SubjectIcon style={{marginRight: "2px"}}/>

                                <TextValidator
                                    name='cardDesc'
                                    id="cardDesc"
                                    label="Description"
                                    placeholder="Enter task description"
                                    multiline
                                    rows={3}
                                    cols={8}
                                    className={classes.textField}
                                    defaultValue={this.state.cardDesc}


                                /></Grid>

                        </Grid>


                        <Grid item xs={12}> <br/>
                            <GroupAddIcon style={{marginRight: "15px", marginTop: "10px"}}/> Add members <br/>
                            <Autocomplete className={classes.addMember}
                                          multiple
                                          options={this.state.currentBoardMembers}
                                          getOptionLabel={option => option.fullName}
                                          defaultValue={this.defaultValueAutoComplete()}
                                          onChange={(evt) => this.handleAddMember(evt)}
                                          disableClearable={true}
                                          renderTags={(value, {className, onDelete}) =>
                                              value.map((option, index) => (
                                                  <Chip
                                                      key={option.id}
                                                      avatar={<Avatar alt={option.avatarHash}
                                                                      src={DefaultAvatar(option)}
                                                                      className="bigAvatar"/>}
                                                      //disabled={index === 0}
                                                      data-tag-index={index}
                                                      tabIndex={-1}
                                                      label={option.fullName}
                                                      className={className}
                                                      onDelete={(e) => this.handleDeleteMember(e, option, value)}
                                                  />
                                              ))
                                          }
                                          renderInput={params => (
                                              <TextField
                                                  {...params}
                                                  className={classes.input}
                                                  variant="outlined"
                                                  fullWidth
                                              />
                                          )}
                            />
                        </Grid>
                    </Grid><br/><br/>
                    <DialogActions>

                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            name="submit"
                            className={classes.submit}
                            disabled={this.state.isLoaded}
                        >
                            Save changes
                        </Button>
                    </DialogActions>

                </ValidatorForm>

            </Dialog>


        );
    }
}

export default withStyles(styles)(CustomizedDialogs);
