import React, { Component } from 'react';
import {TextField, InputAdornment} from "@material-ui/core";
//import InputAdornment from "@material-ui/core/InputAdornment";

import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = theme => ({
    searchBox: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
        backgroundColor: "#fff",
        borderColor: "#673ab7", opacity: "1",
        borderStyle: "solid"
    },
    centerAdornment: {
        // marginLeft: "4px",

    }
});

class SearchBox extends Component{

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {classes} = this.props;
        // const {searchChange} = this.props.searchChange;
        debugger;
        const SearchBox = ({searchField, searchChange}) => {
            return (<TextField variant="outlined"
                               autoFocus
                               className={classes.searchBox}
                               onChange={this.props.searchChange}
                               value={this.props.searchField}
                               placeholder={"Search"}
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment
                                           position="start"
                                           classes={{positionStart: classes.centerAdornment}}
                                       >
                                           <SearchIcon />
                                       </InputAdornment>)
                               }}
                />)
        };

        return (
           <SearchBox/>
        );
    }
}


export default withStyles(useStyles)(SearchBox);
