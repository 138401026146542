import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { GraphPolicyData,GraphCommissionData } from './constantNavigate.js';
import { Line, LineChart, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    root: {
        width: 200,
        flexGrow: 5,
        '& > *': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 450
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 450,
    },
    margin: {
        height: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(20),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
     paper2: {
        height: 300,
        padding: theme.spacing(20),
        margin: 'auto',
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 120,
    },
    dashboardLayout: {
        boxsizing: 'border-box',
        borderradius: '4px',
        boxshadow: '0px 1px 3px 0px #00000033, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #0000001f',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundcolor: '#fff',
        flexwrap: 'wrap',
    },
    central:{
        padding: 'inherit',
        textAlign: 'center',
    },

    //style for font size
    resize:{
        fontSize:50
    },
}));

/*
const marks = [
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },

];*/




class SetGoals extends Component{


    constructor(props) {
        super(props);
         this.state = {
            weeklyPolicies: 0,
            isLoaded:true,
            selection:3
     };
        this.getGoal = this.getGoal.bind(this)

    }


    getGoal(event) {
        debugger;
        console.log(event)
       /*this.setState({
            weeklyPolicies:  event
       })*/
    };

    handleMonthChange(event, index, value) {
        //set selection to the value selected
        this.setState({ selection : value });

    }


    render() {
     /*function valueLabelFormat(value) {
            return marks.findIndex(mark => mark.value === value) + 1;
        }*/

        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={5} >
                    {/* Customer Centricity... */}
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper className={classes.paper}>
                            <Typography variant="h5" component="h3"><PersonOutlineIcon />My weekly goals</Typography><br/><br/>
                            <Grid container spacing={3}>
                                  <Grid item xs>

                                    <Paper className={classes.paper}  style={{ padding: '2rem' }} direction="row">
                                        <Typography  >Select your policy goal</Typography><br/><br/>
                                            <Slider
                                                width={20}
                                                valueLabelDisplay="on"
                                                value={this.state.weeklyPolicies}
                                                getAriaValueText={this.getGoal}
                                                aria-labelledby="discrete-slider-always"
                                                step={1}
                                                marks
                                                min={3}
                                                max={10}
                                                style={{ width: '20rem' }}
                                            />


                                        <br/><br/>
                                        <form className={classes.root} noValidate autoComplete="off">
                                            <TextField
                                                id="standard-textarea"
                                                label="Goal"
                                                placeholder="Goal"
                                                multiline
                                                className={classes.textField}
                                                margin="normal"
                                                value={this.state.weeklyPolicies}
                                            /> <br/>
                                            <TextField
                                                id="standard-textarea"
                                                label="Actual"
                                                placeholder="Actual"
                                                multiline
                                                className={classes.textField}
                                                margin="normal"
                                                value={'0'}
                                            /><br/>
                                            <TextField
                                                id="standard-textarea"
                                                label="Commission"
                                                placeholder="Commission"
                                                multiline
                                                className={classes.textField}
                                                margin="normal"
                                                value={'R2500'}
                                            />
                                        </form>
                                    </Paper>
                                </Grid>

                                <Grid item xs>
                                    <Paper className={classes.paper}  style={{ padding: '2rem' }}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Paper className={classes.paper}>  <Typography >&nbsp;Goals vs Actual</Typography></Paper>
                                            </Grid>
                                            <Grid item xs={6}  >
                                                <Paper className={classes.paper}> </Paper>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <FormControl className={classes.formControl}>
                                                    <Select
                                                        value={this.state.selection}
                                                        onChange={this.handleMonthChange}
                                                    >

                                                        <MenuItem value={1} primaryText="January"  />
                                                        <MenuItem value={2} primaryText="February" />
                                                        <MenuItem value={3} primaryText="March" />
                                                        <MenuItem value={4} primaryText="April" />
                                                        <MenuItem value={5} primaryText="May" />
                                                        <MenuItem value={6} primaryText="June" />
                                                        <MenuItem value={7} primaryText="July" />
                                                        <MenuItem value={8} primaryText="August" />
                                                        <MenuItem value={9} primaryText="September" />
                                                        <MenuItem value={10} primaryText="October" />
                                                        <MenuItem value={11} primaryText="November" />
                                                        <MenuItem value={12} primaryText="December" />
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <LineChart width={500} height={200} data={GraphPolicyData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                            <Line type="monotone" dataKey="Goal"   stroke="#38908F" yAxisId={0} />
                                            <Line type="monotone" dataKey="Actual"   stroke="#6EB5FF" yAxisId={0} />
                                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                        </LineChart>
                                        <Grid item xs={4}>
                                            <Paper className={classes.paper}>  <Typography >&nbsp;Weekly commission</Typography></Paper>
                                        </Grid>
                                        <BarChart width={500} height={200} data={GraphCommissionData} margin={{ top: 20, right: 30, left: 20, bottom: 5, }}  >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip dataKey="comm"/>
                                            <Legend />

                                             <Bar dataKey="Goal" stackId="a" fill="#8884d8" />
                                            <Bar dataKey="Actual" stackId="b" fill="#82ca9d" />

                                        </BarChart>


                                    </Paper>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        );
      }
}
 export default withStyles(useStyles)(SetGoals)
