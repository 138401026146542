import React, { Component } from 'react';
import {fade, withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import StoreService from "../shared/StoreService";
import SearchBox from '../layout/Widget/SearchBox';
import Loader from 'react-loader-spinner';
import CardThirdPartyEntity from "./Widget/CardThirdPartyEntity";
import Card from "@material-ui/core/Card";


const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        position: 'sticky',
        background: fade("#673ab7", 0.07)
    },
    alignMiddle: {
       height:  '75%',
        margin: 'auto',
        display: 'block',

    },
    media: {
        height: 100,
    },
    alignLoader:{
        textAlign: 'center'
    }
});


class GetUpSolution extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            solutions: [],
            searchField: '',
            error: ''
        };

        this.params = {
            filteredSolution: null
        };

        this.StoreService = new StoreService();
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        this.getSolutionInfo();
    }

    getSolutionInfo() {
        console.log('Retrieving solutions data..');
        this.StoreService
            .getSolutionsData()
            .then(res => res.data)
            .then(
                response => {
                    this.setState({
                        solutions: response

                    });
                    console.log(JSON.stringify(this.state.solutions))
                },
                error => {
                    this.setState({
                        error
                    });
                }
            )
    };

    onSearchChange = (event) => {
        this.setState({
            searchField : event.target.value
        });
    };


    render() {
        const { classes } = this.props;
        const { solutions, searchField } = this.state;
        console.log('Current State: ' + JSON.stringify(this.state));
        const filteredSolution = this.state.solutions.filter(solutions => {
            return solutions.title.toLowerCase().includes(searchField.toLowerCase());
        });
        return (!solutions.length) ? <div className={classes.alignLoader}><Loader type="ThreeDots" color="#3f51b5" height={80} width={80} /></div>  : (

            <div className={classes.root}>
                <Grid container  direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <div className={classes.alignmiddle}>
                              <SearchBox searchChange={this.onSearchChange} searchField={searchField} />
                            </div>
                        </Paper>
                    </Grid>
                    {
                        filteredSolution.map((data, index) => {
                         debugger;
                            return (<Grid item xs={12} sm={6} md={3} key={index} justify="flex-start"
                                          alignItems="center">
                                <Card>
                                    <CardThirdPartyEntity data={
                                        {
                                            url: `${data.externalUrl}`,
                                            image: `${data.imageUrl}`,
                                            organizationTag: `${data.title}`,
                                            organizationName: `${data.companyName}`,
                                            organizationLogo: `${data.companyLogo}`,
                                            organizationQuotes: `${data.description}`
                                        }
                                    }/>
                                </Card>
                            </Grid>);
                    })
                    }
                </Grid>
            </div>

        );

    }



}

export default withStyles(useStyles)(GetUpSolution);
