import { Component } from 'react'
import axios from 'axios';
import {API_CONFIG} from "../../config/api-config";
import {MET_RETAIL_API_CONFIG, MET_RETAIL_ADMIN_API_CONFIG} from "../../config/api-config";


export class avatarService extends Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.params = {
      //apiUrl: "https://api.trello.com/1/members/me",
      apiUrl: API_CONFIG.concat('/trello/getMemberInformation'),
      apiQuotes: "https://type.fit/api/quotes",
      getUpFeeds: API_CONFIG.concat('/feeds/getCampaignFeeds')
      };
  }

 /*Get Avatar*/
 GetTrelloBoardAvatar=(data) => {
   const headers = {
       "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*"
   };
    return axios.get(this.params.apiUrl  +  '/?key=' + data.key +'&token='  + data.token,headers)
   }


   /**Get user trello access */
 GetTrelloUserAccessWarehouse=(data) => {
   const headers = {
       "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*"
   };
  return axios.get(API_CONFIG.concat('/trello/lookUpTrelloMemberbyUsernameWarehouse?domain_username=')  + data.username ,headers);
 }

    /**Get user Role */
    GetUserRole=(data) => {
        debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
       return axios.get(API_CONFIG.concat('/trello/getAccessUserRole?employeeNum=')  + data.employeeNumber ,headers);
     // return axios.get(  "http://api_dev-internal.metropolitan.co.za/flow-api/trello/getAccessUserRole?employeeNum=" + data.employeeNumber,headers );

    }

    /*Get Access Via AD*/
 GetUpAccessAD=(data) => {
  var session_url = MET_RETAIL_API_CONFIG;

  return axios.post(session_url, {}, {
    auth: {
      username: data.email,
      password: data.password
    },
    headers: {'Access-Control-Allow-Headers': '*'}
  });
 }

    /*Get Access Admin Via AD*/
    GetUpAdminAccessAD=(data) => {
      var session_url = MET_RETAIL_ADMIN_API_CONFIG;


      debugger;


      return axios({
        method: 'post',
        url: session_url,
        data: {
          userName: data.email,
          primaryRole: data.adminRole,
          minicusername: data.minicusername
        },
        headers: {'Access-Control-Allow-Headers': '*','Authorization': 'Bearer '.concat(btoa(data.email.concat(':', data.password)))}
        });


     }

      /*Trail Access Admin Oauth*/
    trailAccessAdminOauth=(data) => {
      //var session_url = MET_RETAIL_ADMIN_API_CONFIG;


      debugger;


      return axios({
        method: 'post',
        url: API_CONFIG.concat('/util/userAuthTrailer'),
        data: data,
        headers: {'Access-Control-Allow-Headers': '*'}
        });


     }

      /*Trail Access Admin Oauth*/
    trailAccessAdminLogOutOauth=(data) => {
      //var session_url = MET_RETAIL_ADMIN_API_CONFIG;


      debugger;


      return axios({
        method: 'post',
        url: API_CONFIG.concat('/util/exitUserAuthTrailer'),
        data: data,
        headers: {'Access-Control-Allow-Headers': '*'}
        });


     }

 /*Get Todays Inspirational Qoutes */
 GetTodaysInspirationalQuote=() => {
   const headers = {
       "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*"
   };
  return axios.get(this.params.apiQuotes,headers);
 }

 /*Get Digital lastest feeds */
 GetDigitalLastestFeeds=() => {
   const headers = {
       "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*"
   };
  return axios.get(this.params.getUpFeeds,headers);
 }



  }

export default avatarService
