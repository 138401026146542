export const creation_notification = [
        {
            creator_member_id: '5de4b49b9af87c8f38071a4e',
            added_member_id: 'dominictoretto4',
            creation_date: '2019-12-11 12:19:06.826753',
            card_id: '5df0c6689b835d0ec9fec9be',
            taskType: {
                taskName: 'Client appointment',
                taskColor: 'pink'
            }
        },
        {
            creator_member_id: '5fe4b49b9af87c8f38071a4e',
            added_member_id: 'dominictoretto4',
            creation_date: '2019-12-11 15:19:06.826753',
            card_id: '5defef5a39946886763e9879',
            taskType: {
                taskName: 'Activation  testing',
                taskColor: 'purple'
            }
        },
        {
            creator_member_id: '5de4b49b9af87f8f38071a4e',
            added_member_id: 'dominictoretto4',
            creation_date: '2019-12-12 13:49:39.709385',
            card_id: '5df0e9f493fbf944d1d5e523',
            taskType: {
                taskName: 'Planning',
                taskColor: 'green'
            }
        }
        
    ];

    export const notification_cardInfo = [
        {
            id: '5df0c6689b835d0ec9fec9be',
            badges: {
              
            },
            checkItemStates: [],
            closed: false,
            dueComplete: false,
            dateLastActivity: '2019-12-11T14:09:40.524Z',
            desc: 'Finalise Income policy agreement with James Bond 007',
            due: '2019-12-11T15:25:00.000+0000',
            idBoard: '5dd558490a11d55cef9699ca',
            idChecklists: [],
            idList: '5dd55870bce38e7a85cd5335',
            idMembers: [],
            idMembersVoted: [],
            idShort: 23,
            manualCoverAttachment: false,
            labels: [
              {
                id: '5de524be6f13ed798c8a6fee',
                idBoard: '5dd558490a11d55cef9699ca',
                name: 'Prospecting',
                color: 'yellow'
              }
            ],
            idLabels: [
              '5de524be6f13ed798c8a6fee'
            ],
            name: 'New Calendar apppointment',
            pos: '360448',
            shortLink: 'NwDvpClO',
            shortUrl: 'https://trello.com/c/NwDvpClO',
            subscribed: false
          },
          {
            id: '5defef5a39946886763e9879',
            badges: {
              
            },
            checkItemStates: [],
            closed: false,
            dueComplete: false,
            dateLastActivity: '2019-12-11T14:09:40.524Z',
            desc: 'good days',
            due: '2019-12-11T15:25:00.000+0000',
            idBoard: '5dd558490a11d55cef9699ca',
            idChecklists: [],
            idList: '5dd55870bce38e7a85cd5335',
            idMembers: [],
            idMembersVoted: [],
            idShort: 23,
            manualCoverAttachment: false,
            labels: [
              {
                id: '5de524be6f13ed798c8a6fee',
                idBoard: '5dd558490a11d55cef9699ca',
                name: 'Prospecting',
                color: 'yellow'
              }
            ],
            idLabels: [
              '5de524be6f13ed798c8a6fee'
            ],
            name: 'Good Days coming along',
            pos: '360448',
            shortLink: 'NwDvpClO',
            shortUrl: 'https://trello.com/c/NwDvpClO',
            subscribed: false
          },
          {
            id: '5df0e9f493fbf944d1d5e523',
            badges: {
              
            },
            checkItemStates: [],
            closed: false,
            dueComplete: false,
            dateLastActivity: '2019-12-11T14:09:40.524Z',
            desc: 'good days notification design',
            due: '2019-12-11T15:25:00.000+0000',
            idBoard: '5dd558490a11d55cef9699ca',
            idChecklists: [],
            idList: '5dd55870bce38e7a85cd5335',
            idMembers: [],
            idMembersVoted: [],
            idShort: 23,
            manualCoverAttachment: false,
            labels: [
              {
                id: '5de524be6f13ed798c8a6fee',
                idBoard: '5dd558490a11d55cef9699ca',
                name: 'Prospecting',
                color: 'yellow'
              }
            ],
            idLabels: [
              '5de524be6f13ed798c8a6fee'
            ],
            name: 'Good Days coming along happy days all the way',
            pos: '360448',
            shortLink: 'NwDvpClO',
            shortUrl: 'https://trello.com/c/NwDvpClO',
            subscribed: false
          }
    ];

   