import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import CardsService from "../shared/cardService";
import {yellow} from "@material-ui/core/colors";
import {ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';
import Loader from "react-loader-spinner";
import Grid from "@material-ui/core/Grid";
import AssignmentIcon from '@material-ui/icons/Assignment';
import SubjectIcon from '@material-ui/icons/Subject';
import LabelIcon from "@material-ui/icons/Label";
import toast from "toasted-notes";
import ShortTextIcon from "@material-ui/icons/ShortText";


const styles = theme => ({

    root: {
        margin: 0,
        padding: theme.spacing(15),

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        padding: '9px',
        boxsizing: 'border-box'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200,
    },
    formControl: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        width: 450
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    addMember: {
        marginLeft: "39px",
        marginTop: "15px",
        // marginRight: theme.spacing(2),
        width: 510
    },

});


class outcomesDialogs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ShowTitleA: 'none',
            ShowTitleB: 'none',
            taskId: '',
            TaskTypeState: '',
            TaskTypename: '',
            isLoaded: false,
            title: '',
            openDialog: this.props.OutComesDialogState,
            TaskData: [],
            OutcomesData: [],
            ProductData: [],
            OutcomeState: '',
            OutcomeStateId: '',
            ProductStateId: '',
            ProductState: '',
            ShowProducts: 'none',
            ShowCourse: 'none',
            ShowOutcomeBtn: 'block',
            CourseName: '',
            comments:'',
            ShowTaskTypeMsg: 'none'
        }
        console.log('Card data info GH ' + JSON.stringify(this.props.data));

        this.handleChangeOutcomeTypes = this.handleChangeOutcomeTypes.bind(this);
        this.handleChangeProductTypes = this.handleChangeProductTypes.bind(this);
        this.handleSubmitData = this.handleSubmitData.bind(this);
        this.CardsService = new CardsService();
        debugger;

        if ((this.props.data.cardContent.MycardContent.labels !== null) && (this.props.data.cardContent.MycardContent.labels !== undefined)) {
            this.GetAllLabels();
            this.GetAllProductTypes();
            this.GetAllOutcomesByTaskType(this.props.data.cardContent.MycardContent.labels[0].name);
            this.setState({
                TaskTypename: this.props.data.cardContent.MycardContent.labels[0].name,
                ShowOutcomes: 'block',
                ShowCourse: 'none',
                ShowProducts: 'none',
                showComments: 'block',
                ShowTitleA: 'none',
                ShowTitleB: 'block',
                ShowTaskTypeMsg: 'block'
            });

        } else {

            this.setState({
                ShowOutcomes: 'none',
                ShowCourse: 'none',
                ShowProducts: 'none',
                showComments: 'none',
                ShowTitleA: 'none',
                ShowOutcomeBtn: 'none',
                ShowTitleB: 'block',
                ShowTaskTypeMsg: 'block'
            });
        }

    }

    GetAllLabels() {
        this.CardsService
            .GetAllCardLabels()
            .then(res => {
                this.setState({

                    TaskData: res.data
                });
            })
            .catch(error => {
                toast.notify("Error retrieving data", {
                    duration: 800
                });
            });
    }


    GetAllProductTypes() {
        debugger;
        this.CardsService
            .retrieveProductData()
            .then(res => {
                this.setState({
                    ProductData: res.data
                });
                console.log('OUTCOME TYPES ' + this.state.OutcomesData)
            })
            .catch(error => {
                toast.notify("Error retrieving data", {
                    duration: 800
                });
            });
    }


    GetAllOutcomesByTaskType(TaskTypeState) {
        debugger;
        this.CardsService
            .retrieveOutcomes(TaskTypeState)
            .then(res => {
                this.setState({
                    OutcomesData: res.data
                });
                console.log('OUTCOME TYPES ' + this.state.OutcomesData)
            })
            .catch(error => {
                toast.notify("Error retrieving data", {
                    duration: 800
                });
            });
    }


    renderOutComesTypes() {
        debugger;

        return this.state.OutcomesData.map((dt1, i) => {
            return (
                <MenuItem
                    key={i}
                    value={dt1.id} title={dt1.outcome}>
                    {dt1.outcome}
                </MenuItem>

            );
        });
    }

    renderProductTypes() {
        return this.state.ProductData.map((dt, i) => {
            return (
                <MenuItem
                    key={i}
                    value={dt.id} title={dt.title}>
                    {dt.title}
                </MenuItem>

            );
        });
    }


    handleChangeProductTypes(event) {
        this.setState({'ShowCourse': 'none'});
        this.setState({ProductStateId: event.target.value});
    }

    handleChangeOutcomeTypes(event) {

        this.setState({'ShowProducts': 'none'});
        this.setState({'ShowCourse': 'none'});
        console.log(event.target.value);
        this.setState({
            OutcomeStateId: event.target.value
        });

        debugger;
        let outcome_Types = event.currentTarget.title;


        switch (outcome_Types) {
            case 'product sold':
                //Validate Product Type

                return this.setState({'ShowProducts': 'block'});
            case 'product proposed - await feedback':
                //Validate Product Type

                return this.setState({'ShowProducts': 'block'});
            case 'learning completed':

                return this.setState({'ShowCourse': 'block'});
            case 'learning not completed':

                return this.setState({'ShowCourse': 'block'});
            case 'learning paused':

                return this.setState({'ShowCourse': 'block'});
            case 'learning cancelled':
                this.setState({'ShowProducts': 'none'})
                return this.setState({'ShowCourse': 'block'});
            default :

                this.setState({'ShowCourse': 'none'});
                this.setState({'ShowProducts': 'none'});
        }

    }

    handleSubmitData = (event) => {

        debugger;
        event.preventDefault();

        let v_Key = this.props.data.cardContent.key;
        let v_Token = this.props.data.cardContent.token;
        let v_CardId = this.props.data.cardContent.cardId;
        let v_LanId = this.props.data.cardContent.sourceLaneId;
        let v_OutcomeTypes = this.state.OutcomeStateId;
        let v_ProductTypes = this.state.ProductStateId
        let v_comments = document.getElementById('Comments').value;
        let v_CourseName = document.getElementById('CourseName').value;


        /* if (this.data.cardContent.MycardContent!== null) {
              v_TaskTypename = this.data.cardContent.MycardContent.labels[0].name;
          }*/

        const OutcomeData = {
            key: v_Key,
            token: v_Token,
            card_Id: v_CardId,
            lane_id: v_LanId,
            outcome_type_Id: v_OutcomeTypes,
            product_Id: v_ProductTypes,
            course: v_CourseName,
            outcome_comment: v_comments,
            task_name: this.state.TaskTypename
        }
        this.setState({
            isLoaded: true,
        });
        this.CardsService
            .CreateOutcome(OutcomeData)
            .then(res => {
                console.log(JSON.stringify(res.data));
                toast.notify(res.data.message, {
                    duration: 4000
                });
                this.props.parentCallbackRefreshBoard({
                    boardId: this.props.data.boardId,
                    addCardToLaneFlag: false,
                    addCardToLaneByCardId: '',
                    addCardToLaneByLaneId: ''
                });
                this.setState({
                    openDialog: false,
                    isLoaded: false,
                });

            })
            .catch(error => {

                toast.notify("Error moving card", {
                    duration: 1200
                });
                this.setState({
                    openDialog: false,
                    isLoaded: false,
                });
            });


        console.log('MY OUT COMES ---<' + JSON.stringify(OutcomeData));

    }


    handleCourseName = (event) => {
        this.setState({CourseName: event.target.value});
    }


    handleCommentsName = (event) => {
        this.setState({comments: event.target.value});
    }



    handleClose = () => {

        this.props.parentCallCloseBoard(this.props.data);

        this.setState({
            openDialog: false
        })

    }


    componentWillReceiveProps(nextProps) {
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isOutcomeTypesSelected');
        ValidatorForm.removeValidationRule('isProductTypesSelected');
        ValidatorForm.removeValidationRule('isCourseName');

}

    componentDidMount() {

        this.setState({OutcomeStateId: ' '});
        this.setState({ProductStateId: ' '});
        debugger;
        if ((this.props.data.cardContent.MycardContent.labels !== null) && (this.props.data.cardContent.MycardContent.labels !== undefined)) {

            this.setState({color: this.props.data.cardContent.MycardContent.labels[0].color})
            this.setState({TaskTypename: this.props.data.cardContent.MycardContent.labels[0].name})
            this.setState({'ShowTitleB': 'block'});
            this.setState({'ShowTitleA': 'none'});
            this.setState({'ShowTaskTypeMsg': 'none'});
        } else {
            this.setState({'ShowOutcomes': 'none'});
            this.setState({'ShowCourse': 'none'});
            this.setState({'ShowProducts': 'none'});
            this.setState({'showComments': 'none'});
            this.setState({'ShowOutcomeBtn': 'none'});
            this.setState({'ShowTitleA': 'block'});
            this.setState({'ShowTitleB': 'none'});
            this.setState({'ShowTaskTypeMsg': 'block'});

        }

        //Validate  Task Type
        ValidatorForm.addValidationRule('isOutcomeTypesSelected', (value) => {

            if (value === ' ') {
                return;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isProductTypesSelected', (value) => {

            // console.log('OUTCOME STATE ID --->' + this.state.OutcomeStateId + 'SHOW -->' + this.state.ShowProducts)
            if (this.state.ShowProducts !== 'none') {
                if (value === ' ') {
                    return false;
                }
                return true;
            } else {
                return true;
            }


        });

        ValidatorForm.addValidationRule('isCourseName', (value) => {
            if (this.state.ShowCourse !== 'none') {
                if (value === ' ') {
                    return false;
                }
                return true;
            } else {
                this.setState({CourseName: 'none'})
                return true;
            }

        });



            ValidatorForm.addValidationRule('IsCommentRequired', (value) => {
                if (this.props.data.cardContent.MycardContent.labels[0].name.toLowerCase() === 'conservation') {
                   if (value === '') {
                        return false;

                    } else {
                        return true;
                    }
                }
                else
                {
                   return true
                }
            });


    }


    render() {

        const {classes} = this.props;
        const DialogTitle = withStyles(styles)(props => {
            const {children, classes, onClose} = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });

        const DialogContent = withStyles(theme => ({
            root: {
                padding: theme.spacing(2),
            },
        }))(MuiDialogContent);

        const DialogActions = withStyles(theme => ({
            root: {
                margin: 0,
                padding: theme.spacing(1),
            },
        }))(MuiDialogActions);


        return (


            <Dialog disableBackdropClick disableEscapeKeyDown onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title" open={this.state.openDialog} width={1200}>


                <DialogTitle style={{backgroundColor: yellow}} id="customized-dialog-title"
                             onClose={this.handleClose}>
                    <Grid container direction="row">
                        <Grid item>
                            <Loader type="ThreeDots" color="#3f51b5" height={30} width={40}
                                    visible={this.state.isLoaded}/>
                        </Grid>

                        <Grid item style={{display: this.state.ShowTitleB}}>
                            &nbsp;&nbsp;<LabelIcon style={{color: this.state.color}}/>&nbsp; Select
                            your {this.state.TaskTypename} outcome &nbsp;&nbsp;
                        </Grid>

                        <Grid item style={{display: this.state.ShowTaskTypeMsg, width: 450}}>
                            Task type required &nbsp;&nbsp;
                        </Grid>

                    </Grid>

                </DialogTitle>
                <ValidatorForm onSubmit={this.handleSubmitData} onError={errors => console.log(errors)} ref="form"
                               id='id_ValidatorForm'>
                    <Grid container>

                        <Grid container spacing={2}>
                            <Grid item xs={12}> <br/>

                                <Grid item style={{display: this.state.ShowTitleA}}>
                                    Please provide a task type for your card before moving it.

                                </Grid>


                                <Grid item xs={12} style={{display: this.state.ShowTitleB}}> <br/>
                                    <ShortTextIcon style={{marginRight: "2px"}}/>

                                    <TextValidator
                                        name="title"
                                        id="title"
                                        label="Card title"
                                        placeholder="Card title"
                                        className={classes.textField}
                                        defaultValue={this.props.data.cardContent.MycardContent.title}
                                        disabled={true}

                                    /></Grid>
                            </Grid>
                            <Grid item xs={12} style={{display: this.state.ShowOutcomes}}> <br/>
                                <span className="star">*</span> <AssignmentIcon style={{marginRight: "2px"}}/>
                                <SelectValidator
                                    id="OutcomeTypesId"
                                    name="OutcomeTypes"
                                    validators={['isOutcomeTypesSelected', 'required']}
                                    errorMessages={['Outcome type is required']}
                                    value={this.state.OutcomeStateId}
                                    onChange={(e) => this.handleChangeOutcomeTypes(e)}
                                    className={classes.textField}
                                >
                                    <MenuItem value={this.state.OutcomeStateId}>Select an outcome</MenuItem>
                                    {this.renderOutComesTypes()}
                                </SelectValidator>
                            </Grid>

                            <Grid item xs={12} style={{display: this.state.ShowProducts}}> <br/>
                                <span className="star">*</span> <AssignmentIcon style={{marginRight: "2px"}}/>
                                <SelectValidator
                                    id="ProductTypesId"
                                    name="ProductTypes"
                                    validators={['isProductTypesSelected', 'required']}
                                    errorMessages={['Product type is required']}

                                    value={this.state.ProductStateId}
                                    onChange={(e) => this.handleChangeProductTypes(e)}
                                    className={classes.textField}
                                >
                                    <MenuItem value={this.state.ProductStateId}>Select a product</MenuItem>
                                    {this.renderProductTypes()}
                                </SelectValidator>
                            </Grid>

                            <Grid item xs={12} style={{display: this.state.ShowCourse}}>
                                <ShortTextIcon style={{marginRight: "2px"}}/>

                                <TextValidator
                                    /*     validators={['isCourseName', 'required']}
                                         errorMessages={['Course name type is required']}*/
                                    name='course'
                                    id="CourseName"
                                    label="Course Name:"
                                    placeholder="Enter Course Name"
                                    className={classes.textField}
                                    value={this.state.CourseName}
                                    onChange={(evt) => this.handleCourseName(evt)}

                                />
                            </Grid>


                            <Grid item xs={12} style={{display: this.state.showComments}}> <br/>

                                {this.state.TaskTypename.toLowerCase()==='conservation' ?  <span className='star'>*</span> :   '' }
                                <SubjectIcon style={{marginRight: '2px'}}/>
                                <TextValidator
                                   value={this.state.comments}
                                    validators={['IsCommentRequired']}
                                    errorMessages={['Comment is required']}
                                    name='Comments'
                                    id="Comments"
                                    label="Comments"
                                    placeholder="Enter outcome comment"
                                    multiline
                                    rows={3}
                                    cols={8}
                                    onChange={(evt) => this.handleCommentsName(evt)}
                                    className={classes.textField}
                                /></Grid>

                        </Grid>


                    </Grid><br/><br/>
                    <DialogActions>

                        <Button style={{display: this.state.ShowTitleB}}
                                color="primary"
                                type="submit"
                                variant="contained"
                                name="submit"
                                className={classes.submit}
                                disabled={this.state.isLoaded}
                        >
                            Save outcome
                        </Button>
                    </DialogActions>

                </ValidatorForm>

            </Dialog>


        );
    }
}

export default withStyles(styles)(outcomesDialogs);
