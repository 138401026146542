import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import * as Cookie from "js-cookie";
import './App.css';
import Layout from './components/layout/Layout';
import SignInSide from './components/layout/SignInSide/SignInSide';
import requireAuth from './components/layout/SignInSide/AuthenticatedComponent';
import {createMuiTheme, fade} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Gilroy-Regular',
    ].join(','),
  },
  palette:  {
    background: {
      default: fade("#9e9e9e", 0.07)
    }
  }
});



class App extends Component {

  constructor(props) {
    super(props);

    this.state = {loginStatus: true};
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    //   this.warn = this.warn.bind(this);
    //   this.logout = this.logout.bind(this);
    //   this.resetTimeout = this.resetTimeout.bind(this);
    //
    //   for (var i in this.events) {
    //     window.addEventListener(this.events[i], this.resetTimeout);
    //   }
    //   this.setTimeout();
    //
    // }
    //
    // clearTimeout() {
    //   if (this.warnTimeout) clearTimeout(this.warnTimeout);
    //
    //   if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    // }
    //
    // setTimeout() {
    //   this.warnTimeout = setTimeout(this.warn, 120000);
    //   this.logoutTimeout = setTimeout(this.logout, 180000);
    // }
    //
    // resetTimeout() {
    //   this.clearTimeout();
    //   this.setTimeout();
    // }
    //
    // warn() {
    //  console.log('About to kick you out!');
    // }
    //
    // logout() {
    //   // Send a logout request to the API
    //   console.log("Sending a logout request...");
    //   this.setState({ loginStatus: false });
    //
    //   if(!this.state.loginStatus){
    //     this.props.history.push('/login');
    //     document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    //     localStorage.clear();
    //     sessionStorage.clear();
    //
    //   }
    //
    //
    //   // this.destroy(); // Cleanup
    // }
    //
    // destroy() {
    //   this.clearTimeout();
    //
    //   for (var i in this.events) {
    //     window.removeEventListener(this.events[i], this.resetTimeout);
    //   }
    // }

  }

  state = {
    auth: true,
  };

  componentDidMount() {
    if (!sessionStorage.getItem('mmih_auth_token')) {
      this.setState({ auth: false });
    }
  }


  render() {
    return (
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <Switch>
                <Route exact path="/login" component={SignInSide} />
                <Route exact path="/" component={requireAuth(Layout)} />
                {!this.state.auth &&  <Redirect push to="/login"/> }
              </Switch>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>

    );
  }
}

export default App;
