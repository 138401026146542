import  {Component} from 'react';
import axios from 'axios';
import {API_CONFIG} from "../../config/api-config";

class PowerBiService  {

params = {
    apiUrl: API_CONFIG.concat('/powerBi/')
};

    /*Get Access Token */
   getAccessToken = (base64Credentials) =>{
       debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization":'Basic ' +  base64Credentials
        };
       return axios({
           method: 'post',
           url:  this.params.apiUrl + 'getPowerBIUserInfo',
           headers: headers
       });

    }


    /**Get user Role */
    GetUserRole=(data) => {
        debugger;
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };
         return axios.get(API_CONFIG.concat('/trello/getAccessUserRole?employeeNum=')  + data.employeeNumber ,headers);
       // return axios.get(  "http://api_dev-internal.metropolitan.co.za/flow-api/trello/getAccessUserRole?employeeNum=" + data.employeeNumber,headers );

    }


    /*Get Access Embeded  Token */
    getReportMyProductionReport(ObjectReportAccess,AccessToken) {
         const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization":"Bearer " +  AccessToken
        };

        return axios({
            method: 'post',
            url:  this.params.apiUrl + 'getEmbeddedAccessToken',
            data: ObjectReportAccess,
            headers: headers
        });


    };

}
export default new PowerBiService();

