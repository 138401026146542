import  {Component} from 'react';
import axios from 'axios';
import {API_CONFIG} from "../../config/api-config";

class StoreService extends Component {

    constructor(props) {
        super(props);
        this.params = {
            apiUrl: API_CONFIG.concat('/feeds/')
        }

    }

    getSolutionsData = () => {
        console.log('Retrieving Solution Data .. ');
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        return axios.get(this.params.apiUrl + 'getAllSolutions', headers);
    };

    getToolsData = () => {
        console.log('Retrieving tools Data .. ');
        const headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        };

        return axios.get(this.params.apiUrl + 'getAllTools', headers);
    }
}

export default StoreService;
