import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import avatarService from '../shared/AvatarService';
import { withStyles } from '@material-ui/core/styles';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import * as Cookie from "js-cookie";


const styles = theme => (({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
}));

class Avatars extends Component {

    constructor(props) {
      super(props);
         this.state = {
        avatar:  '' ,
        avatarUrl: '',
        fullName:'',
        initials:'',
        username:''
             //define your initial state
      }
       this.avatarService = new avatarService();
       
    }

    componentDidMount(){
      //this.fetchTrelloAvatar();

    }



    fetchTrelloAvatar = () => {
      this.avatarService
        .GetTrelloBoardAvatar(this.props.data)
        .then(res => res.data)
        .then(
          response => {
            this.setState({
              avatar : response 
            });
          },
          error => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    };
 
     render() {
       const { classes } = this.props;
        debugger;
      let member_uuid = JSON.parse(Cookie.get('member_uuid'));      
      const avatar_img =(avatar) =>{
        if(typeof avatar !== 'undefined'){
          return <Avatar alt={member_uuid.fullName} src={member_uuid.avatarUrl  + "/170.png"}  className="bigAvatar" />
        }else{

        return <Avatar className={classes.orange}>{this.props.user.charAt(0)}</Avatar>

        }

      }
        return(
         
          <Grid container justify="center" alignItems="center">
            {avatar_img(member_uuid.avatarUrl)}      
        
         </Grid>
        );
      }
    }

    export default withStyles(styles)(Avatars);
