import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import boardsService from "../shared/boardsService";
// Dialog
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TextField from '@material-ui/core/TextField';
import SubjectIcon from '@material-ui/icons/Subject';
import CheckIcon from '@material-ui/icons/Check';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import toast from "toasted-notes";
import LabelIcon from '@material-ui/icons/Label';
import CardsService from './../shared/cardService';
import DomainIcon from '@material-ui/icons/Domain';
import * as Cookies from "js-cookie";
import Loader from 'react-loader-spinner';
import moment from 'moment';
import ShortTextIcon from '@material-ui/icons/ShortText';
import LoadingOverlay from 'react-loading-overlay';





//import './main.scss' // webpack must be configured to do this
import './../../../node_modules/@fullcalendar/core/main.css';
import './../../../node_modules/@fullcalendar/daygrid/main.css';
import './../../../node_modules/@fullcalendar/timegrid/main.css';
import './../../../node_modules/@fullcalendar/list/main.css';




const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textfieldWidth: {
    width: '100%',
  },
  dailogWidth: {
    minWidth: '80vh',
    maxWidth: '80vh',
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  alignLoader: {
    textAlign: 'center'
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


class ToDoCalendarLayoutHub extends Component {

  constructor(props) {
    super(props);

    this.boardsService = new boardsService();
    this.CardsService = new CardsService();
    this.currentDate = this.currentDate.bind();
    this.state = {
      calendarTrelloEvents: [],
      open: false,
      openEvent: false,
      overlay:false,
      openEventStartDate: '',
      openEventEndDate: '',
      openEventAttendees: '',
      openEventDescriptin: '',
      currentDateSelected: '',
      openEventTitle: '',
      TaskTypeState: 'Select task type',
      BoardTypeState: 'Select Worksite Board',
      TaskData: [],
      BoardData: [],
      employeeNo: props.data.employeeNo,
      samaccountName: props.data.samaccountName,
      text_add_title: '',
      text_start_date: "2019-12-24T09:25",
      text_end_date: "2019-12-24T10:25",
      text_add_email: '',
      text_add_emailItem: [],
      text_add_emailValue: '',
      department_UUid: '',
      department_id: '',
      isLoaded: false,
      error: null,
      storeCardIdInfo:''
    };
  }

  handlePasteEmail = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      this.setState({
        text_add_emailItem: [...this.state.text_add_emailItem, ...toBeAdded]
      });
    }
  };

  handleChangeEmail = evt => {
    this.setState({
      text_add_emailValue: evt.target.value,
      text_add_email: evt.target.value,
      error: null
    });
  };
  handleDeleteEmail = item => {
    this.setState({
      text_add_emailItem: this.state.text_add_emailItem.filter(i => i !== item)
    });
  };

  handleKeyDown = evt => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.text_add_emailValue.trim();

      if (value && this.isValid(value)) {
        this.setState({
          text_add_emailItem: [...this.state.text_add_emailItem, this.state.text_add_emailValue],
          text_add_emailValue: "",
          text_add_email: ''
        });
      }
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.text_add_emailItem.includes(email);
  }

  isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  handleTitleChange = (event) => {

    this.setState({ text_add_title: event.target.value, openEventTitle: event.target.value });
  }
  handleStartDateChange = (event) => {

    this.setState({ text_start_date: event.target.value, openEventStartDate: event.target.value });
  }
  handleEndDateChange = (event) => {

    this.setState({ text_end_date: event.target.value, openEventEndDate: event.target.value });
  }
  handleEmailChange = (event) => {
    debugger;

    this.setState({ text_add_email: event.target.value, openEventAttendees: event.target.value, text_add_emailValue: event.target.value });
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };
  handleClose = () => {
    this.setState({
      open: false,
      openEvent: false
    });
  };

  GetAllLabels() {
    this.CardsService
      .GetAllCardLabels()
      .then(res => {
        this.setState({
          //   TaskTypeState: 'SELECT A TASK TYPE',
          TaskData: res.data
        });
      })
      .catch(error => {
        toast.notify("Error retrieving data", {
          duration: 3000
        });
      });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isEndDateGreaterStartDateCheck');
    ValidatorForm.removeValidationRule('isTaskTypeSelected');
    ValidatorForm.removeValidationRule('isWorksiteBoardSelected');

  }


  componentDidMount() {

    ValidatorForm.addValidationRule('isEndDateGreaterStartDateCheck', (value) => {
      // alert('Start Date ' + this.state.startDate + ' ' +  'End Date' +  value )
      let isafter = moment(value).isAfter(this.state.text_start_date);
      if (this.state.openEvent) {
        isafter = moment(value).isAfter(this.state.openEventStartDate);
      }
      if (isafter === false) {
        return false;
      }
      return true;


    });

    //Validate  Task Type
    ValidatorForm.addValidationRule('isTaskTypeSelected', (value) => {
      if (value === 'Select task type') {
        return false;
      }
      return true;
    });

    //Validate  Task Type
    ValidatorForm.addValidationRule('isWorksiteBoardSelected', (value) => {
      if (value === 'Select Worksite Board') {
        return false;
      }
      return true;
    });

    debugger;
    this.CardsService.GetCalendarO365(this.props.data).then(res => res.data).then(
      response => {
        let calendarTrelloEvents = []
        for (let result = 0; result < response.calendarEventResponses.length; result++) {
          var start_date = new Date(response.calendarEventResponses[result].startDate);
          var end_date = new Date(response.calendarEventResponses[result].endDate);//'2019-11-04T07:00:00'
          let addressAttendees = [];
          response.calendarEventResponses[result].attendees.forEach(function (item) {

            addressAttendees.push(item.emailAddress.address);
          });

          calendarTrelloEvents.push(
            {

              title: response.calendarEventResponses[result].subject,
              start: start_date.toISOString(),
              end: end_date.toISOString(),
              backgroundColor: '#2C3E50',
              borderColor: '#2C3E50',
              extendedProps: {
                department_UUid: response.calendarEventResponses[result].event_iCalUId,
                _id: response.calendarEventResponses[result].event_id,
                _listAddress: addressAttendees,
              },
              description: response.calendarEventResponses[result].bodyPreview
            });
        }
        sessionStorage.setItem("mmih_accessToken", response.user_access_token);
        localStorage.setItem('mmih_accessToken', response.user_access_token);
        this.setState({
          calendarTrelloEvents: calendarTrelloEvents
        });
      },
      error => {
        toast.notify("Error retrieving data" + error, {
          duration: 3000
        });
      }
    );
    this.GetAllLabels();
    this.boardsService
      .GetTrelloBoardNavigation(this.props.data)
      .then(res => res.data)
      .then(
        response => {

          /**
           * Defect fix: 'Technical Support’ should not be an option on select list under Worksite board on Calendar
           * Date: 05-02-2020
           */
          let removeTechBoard = [];
          for (let rm = 0; rm < response.length; rm++) {
            if (response[rm].id !== "5dfa3f46d8bdc570fd6d723f") removeTechBoard.push(response[rm]);
          }

          this.setState({
            //   TaskTypeState: 'SELECT A TASK TYPE',
            //BoardData: response
            BoardData: removeTechBoard
          });

        },
        error => {
          toast.notify("Error retrieving data" + error, {
            duration: 3000
          });
        }
      );

  }






  currentDate = current => {

    var date = (current === '') ? new Date() : new Date(current);
    var aaaa = date.getFullYear();
    var gg = date.getDate();
    var mm = (date.getMonth() + 1);

    if (gg < 10)
      gg = "0" + gg;

    if (mm < 10)
      mm = "0" + mm;

    var cur_day = aaaa + "-" + mm + "-" + gg;

    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds();

    if (hours < 10)
      hours = "0" + hours;

    if (minutes < 10)
      minutes = "0" + minutes;

    if (seconds < 10)
      seconds = "0" + seconds;

    return cur_day + "T" + hours + ":" + minutes + ":" + seconds;

  }



  renderTaskBoardTypesOptions() {

    return this.state.TaskData.map((dt, i) => {
      return (
        <MenuItem
          key={i}
          value={dt.id} title={dt.name} name={dt} data-my-value={JSON.stringify(dt)}>
          {dt.name}
        </MenuItem>

      );
    });
  }
  renderTaskTypesOptions() {
    return this.state.TaskData.map((dt, i) => {
      return (
        <MenuItem
          key={i}
          value={dt.name} title={dt.description} name={dt} data-my-value={JSON.stringify(dt)}>
          <LabelIcon style={{ color: dt.color }} />
          &nbsp; {dt.name}
        </MenuItem>

      );
    });
  }

  renderBoardTypeStateOptions() {
    return this.state.BoardData.map((dt, i) => {
      return (
        <MenuItem
          key={i}
          value={dt.id} title={dt.name} name={dt} data-my-value={JSON.stringify(dt)}>

          {dt.name}
        </MenuItem>

      );
    });

  }

  handleDateClick = (arg) => { // bind with an arrow function
    let currentTimeDate = new Date(arg.dateStr);
    let currentTime = ((currentTimeDate.getHours() < 10) ? "0" : "") + currentTimeDate.getHours() + ":" + ((currentTimeDate.getMinutes() < 10) ? "0" : "") + currentTimeDate.getMinutes() + ":" + ((currentTimeDate.getSeconds() < 10) ? "0" : "") + currentTimeDate.getSeconds();

    this.setState({
      open: true,
      TaskTypeState: 'Select task type',
      BoardTypeState: 'Select Worksite Board',
      currentDateSelected: arg.dateStr,
      text_start_date: this.findExactDate(currentTimeDate),
      text_end_date: this.findExactDate(currentTimeDate.setHours(currentTimeDate.getHours() + 1)),
      text_add_title: '',
      text_add_emailItem: []

    })
  }

  handleChangeTaskTypes(event) {

    this.setState({
      TaskTypeState: event.target.value
    });

  }

  handleChangeEventDescriptin(event) {
    this.setState({
      openEventDescriptin: event.target.value
    });
  }

  handleChangeBoardTypes(event) {
    this.setState({
      BoardTypeState: event.target.value
    });
  }
  findExactDate(currentdate) {
    var today = new Date(currentdate);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var hh = today.getHours();
    var min = today.getMinutes();

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    if (hh < 10) {
      hh = '0' + hh;
    }
    if (min < 10) {
      min = '0' + min;
    }
    return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min;

  }

  handleEventClickInfo(e, info) {

    let start = this.findExactDate(e.event.start);
    let end = this.findExactDate(e.event.end);
    console.log('console log start date ', start);
    console.log('console log end date ', end);
    let titleEventName = e.event.title;

    const data = {
      _userToken: this.props.data.token,
      _userKey: this.props.data.key,
      eventId: e.event.extendedProps._id,
      cardId: ''


    };
    

    this.CardsService
      .lookUpAllCardDetailStore(data)
      .then(res => {
        const labelInfo = (dt) => {
          return (
            <MenuItem
              value={dt.name} title={dt.description} name={dt} data-my-value={JSON.stringify(dt)}>
              <LabelIcon style={{ color: dt.color }} />
              &nbsp; {dt.name}
            </MenuItem>);
        }
        
        this.setState({
          TaskTypeState: labelInfo(res.data.tasksDTO),
          BoardTypeState: res.data.boardsInfoDTO.name,
          storeCardIdInfo: res.data.CardStoreDTO.cardId,
          overlay:false
        });

      }).catch(error => {

        this.CardsService.
        SearchAllTrelloCards({key: this.props.data.key, token:this.props.data.token,query:titleEventName}).
        then(result =>{
          debugger;
          let storeCardName ='', storeCardId = '', storeLaneId = '', storeBoardId = '',storeBoardName = '', cardInfoData = result.data.cards;
          let searchEntry = false;
          let storeCardInfo = {
          name: '',
          cardId: '',
          laneId:'',
          worksiteId:'',
          labelInfoDTO:''
          };
          for(let pp = 0; pp < cardInfoData.length ; pp++){
            if(cardInfoData[pp].name === this.state.currentOpenEvent_trueData._openEventTitle){
              storeCardInfo.name = cardInfoData[pp].name;
              storeCardInfo.cardId = cardInfoData[pp].id;
              storeCardInfo.laneId = cardInfoData[pp].idList;
              storeCardInfo.worksiteId = cardInfoData[pp].idBoard;
              storeCardInfo.labelInfoDTO = cardInfoData[pp].labels
              for(let po = 0;po <this.state.BoardData.length; po++){
              if(this.state.BoardData[po].id === cardInfoData[pp].idBoard){
                    storeBoardName = this.state.BoardData[po].name; 
                    break;
                    }
              }
              searchEntry = true;              
              
            }
          }
          if(searchEntry){  
            const labelsInfo = (dt) => {
              return (
                <MenuItem
                  value={dt[0].name} title={dt[0].description} name={dt[0]} data-my-value={JSON.stringify(dt)}>
                  <LabelIcon style={{ color: dt[0].color }} />
                  &nbsp; {dt[0].name}
                </MenuItem>);
            } 
            debugger;    
            this.setState({
                   TaskTypeState: (storeCardInfo.labelInfoDTO.length > 0)?labelsInfo(storeCardInfo.labelInfoDTO):'Select task type',
                   BoardTypeState: storeBoardName,
                   storeCardIdInfo: storeCardInfo.cardId,
                   overlay:false
                    }); 
          }
        }).catch(error =>{
          toast.notify("We noticed that the current event does not map to any worksite or card, please update", {
            duration: 3000
          });
          this.setState({
            isLoaded: false,
            error
          });
        });



        
      });



    this.setState({
      openEvent: true,
      overlay:true,
      openEventStartDate: start,
      openEventEndDate: end,
      openEventAttendees: '',
      text_add_emailItem: e.event.extendedProps._listAddress,
      openEventDescriptin: e.event.extendedProps.description,
      openEventTitle: e.event.title,
      TaskTypeState: 'Select task type',
      BoardTypeState: 'Select Worksite Board',
      department_UUid: e.event.extendedProps.department_UUid,
      department_id: e.event.extendedProps._id,
      currentOpenEvent_trueData: {
        _openEventTitle: e.event.title,
        _text_add_emailItem: e.event.extendedProps._listAddress,
        _openEventStartDate: start,
        _openEventEndDate: end,
        _openEventDescriptin: e.event.extendedProps.description
      }
    });


    // change the border color just for fun
    e.el.style.borderColor = 'red';
  }

  handleSubmitData = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    let title = data.get('input_text_add_title');
    let start_date = data.get('input_text_start_date');
    let end_date = data.get('input_text_end_date');
    let email = data.get('input_text_add_email');
    let description = data.get('input_text__add_description');
    let task_type = data.get('input_text_taskTypes');
    let boardtype = data.get('input_text_BoardTypes');
    let /*cardId = '',*/ laneId = '';

    const parseJwt = token => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    let employeeNo = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.employeeNumber;
    let samaccountName = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.sAMAccountName;
    this.setState({
      isLoaded: true
    });
    const objCreateCard = {
      key: this.props.data.key,
      token: this.props.data.token,
      laneId: '',
      cardId: '',
      cardName: '' + title,
      dueDate: end_date,
      cardDesc: description,
      employeeNo: employeeNo,
      samaccountName: samaccountName
    },
      LabelData = {
        name: task_type,
        color: '',
        cardId: '',
        key: this.props.data.key,
        token: this.props.data.token,
        id: ''

      }
      ;

    const findColor = this.state.TaskData.find(element => element.name === task_type);
    if (typeof findColor.color !== undefined) {
      LabelData.color = findColor.color;
      LabelData.id = findColor.id
    }
    let attendingEmail = [];
    for (let tt = 0; tt < this.state.text_add_emailItem.length; tt++) {

      attendingEmail.push({
        "name": (this.state.text_add_emailItem[tt] !== undefined || this.state.text_add_emailItem[tt] !== '') ? this.state.text_add_emailItem[tt].substring(0, this.state.text_add_emailItem[tt].lastIndexOf("@")) : '',
        "address": this.state.text_add_emailItem[tt],
        "type": "REQUIRED"
      });

    }




    this.CardsService
      .GetAllTrelloCardsPerLane(this.props.data, boardtype)
      .then(res => {

        for (let i = 0; i < res.data.lanes.length; i++) {
          let laneName = res.data.lanes[i].title.toLocaleLowerCase();
          if (laneName.localeCompare('backlog') === 0 ||
            laneName.localeCompare('back-log') === 0) {
            laneId = res.data.lanes[i].id;
            break;
          }
        }
        objCreateCard.laneId = laneId;
        // create card
        this.CardsService.addCardToLane(objCreateCard)
          .then(res => {
            toast.notify(res.data.message, {
              duration: 3000
            });
            // Add label
            LabelData.cardId = res.data.cardId;
            this.CardsService
              .HandelLabels(LabelData)
              .then(res => {
                return this.CardsService.createOffice365EventV1({
                  subject: title,
                  bodyContent: description,
                  startDate: start_date,
                  endDate: end_date,
                  calendarLocationName: "",
                  attendeeList: attendingEmail,
                  cardId: LabelData.cardId,
                  cardDTO: {
                    cardId: LabelData.cardId,
                    taskId: LabelData.id,
                    worksiteId: boardtype
                  }
                });
              })
              .then(result => {

                this.setState({
                  open: false
                });

                toast.notify(result.data.message, {
                  duration: 3000
                });
                const addMember = {
                  key: this.props.data.key,
                  token: this.props.data.token,
                  cardId: LabelData.cardId,
                  employeeNum: employeeNo,
                  idMember: '',
                  samaccountName: samaccountName

                };

                this.CardsService.AddMemberToCard(addMember).then(res => res.data).then(
                  response => {

                    toast.notify("Member Added to card", {
                      duration: 3000
                    });

                  },
                  error => {
                    this.setState({
                      isLoaded: false
                    });

                    toast.notify("Error retrieving data" + error, {
                      duration: 3000
                    });
                  }
                );

                this.CardsService.GetCalendarO365(this.props.data).then(res => res.data).then(
                  response => {

                    let calendarTrelloEvents = []
                    for (let result = 0; result < response.calendarEventResponses.length; result++) {
                      var start_date = new Date(response.calendarEventResponses[result].startDate);
                      var end_date = new Date(response.calendarEventResponses[result].endDate);//'2019-11-04T07:00:00'
                      let addressAttendees = [];
                      response.calendarEventResponses[result].attendees.forEach(function (item) {

                        addressAttendees.push(item.emailAddress.address);
                      });

                      calendarTrelloEvents.push(
                        {
                          title: response.calendarEventResponses[result].subject,
                          start: start_date.toISOString(),
                          end: end_date.toISOString(),
                          backgroundColor: '#2C3E50',
                          borderColor: '#2C3E50',
                          extendedProps: {
                            department_UUid: response.calendarEventResponses[result].event_iCalUId,
                            _id: response.calendarEventResponses[result].event_id,
                            _listAddress: addressAttendees,
                          },
                          description: response.calendarEventResponses[result].bodyPreview
                        });



                    }
                    sessionStorage.setItem("mmih_accessToken", response.user_access_token);
                    localStorage.setItem('mmih_accessToken', response.user_access_token);
                    this.setState({
                      isLoaded: false,
                      calendarTrelloEvents: calendarTrelloEvents
                    });

                  },
                  error => {
                    this.setState({
                      isLoaded: false
                    });

                    toast.notify("Error retrieving data" + error, {
                      duration: 3000
                    });
                  }
                );

              })
              .catch(error => {
                this.setState({
                  isLoaded: false
                });
                toast.notify("Error adding Tasks type ", {
                  duration: 3000
                });
              });

          }).catch(error => {
            this.setState({
              isLoaded: false
            });
            toast.notify("Error adding card " + error, {
              duration: 3000
            });
          });
      }, error => {
        this.setState({
          isLoaded: false,
          error
        });
      }
      );
  }

  handleDeleteChanges = (event) => {

    event.preventDefault();

    let task_type = document.getElementById('input_text_taskTypesEvent');
    let cardId = '', laneId = '';
    const parseJwt = token => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    let employeeNo = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.employeeNumber;
    let samaccountName = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.sAMAccountName;


    const
      objUpdateEventData = {


        eventId: this.state.department_id,
        eventICal_id: this.state.department_UUid,
        subject: this.state.openEventTitle,
        bodyContent: this.state.openEventDescriptin,
        startDate: this.state.openEventStartDate,
        endDate: this.state.openEventEndDate,
        calendarLocationName: '',
        attendeeList: [],
        body: null,
        taskName:'',
        worksiteId:'',
        boardName:this.state.BoardTypeState,
        cardId:this.state.storeCardIdInfo,
        trelloKey :this.props.data.key,
        trelloToken: this.props.data.token

      };
      if(this.state.TaskTypeState !== "Select task type"){
        if(typeof this.state.TaskTypeState.props.name !== undefined){
          objUpdateEventData.taskName = this.state.TaskTypeState.props.value;
        }
      }
      
      debugger;
    this.setState({
      isLoaded: true
    });
    this.CardsService.deleteOffice365Event(objUpdateEventData).then(res => res.data).then(
      response => {
        toast.notify(response.message, {
          duration: 3000
        });
        let deleteCardInfo = {
          cardDesc: '',
          cardId: '', //Required
          cardName: '',
          closed: true, //Required
          dueDate: '',
          key: this.props.data.key,//Required
          laneId: '', //Required
          position: '',
          taskId: 0,
          token: this.props.data.token,//Required
          worksiteId: ''
        };
        this.CardsService.
          SearchAllTrelloCards({ key: this.props.data.key, token: this.props.data.token, query: this.state.currentOpenEvent_trueData._openEventTitle }).
          then(result => {
            debugger;
            let cardInfoData = result.data.cards;
            let searchEntry = false;
            for (let pp = 0; pp < cardInfoData.length; pp++) {
              if (cardInfoData.name === this.state.currentOpenEvent_trueData._openEventTitle) {
                deleteCardInfo.name = cardInfoData.name;
                deleteCardInfo.cardId = cardInfoData.id;
                deleteCardInfo.laneId = cardInfoData.idList;
                deleteCardInfo.worksiteId = cardInfoData.idBoard;
                searchEntry = true;
              }
            }
            if (searchEntry) {
              this.CardsService.DeleteCard(deleteCardInfo).then(res => {
                toast.notify(res.data.message, {
                  duration: 3000
                });
              }).catch(error => {
                toast.notify('Error adding card ' + error, {
                  duration: 3000
                });
              });
            }
            this.CardsService.GetCalendarO365(this.props.data).then(res => res.data).then(
              response => {
    
    
                let calendarTrelloEvents = []
                for (let result = 0; result < response.calendarEventResponses.length; result++) {
                  var start_date = new Date(response.calendarEventResponses[result].startDate);
                  var end_date = new Date(response.calendarEventResponses[result].endDate);//'2019-11-04T07:00:00'
                  let addressAttendees = [];
                  response.calendarEventResponses[result].attendees.forEach(function (item) {
    
                    addressAttendees.push(item.emailAddress.address);
                  });
                  calendarTrelloEvents.push(
                    {
                      title: response.calendarEventResponses[result].subject,
                      start: start_date.toISOString(),
                      end: end_date.toISOString(),
                      backgroundColor: '#2C3E50',
                      borderColor: '#2C3E50',
                      extendedProps: {
                        department_UUid: response.calendarEventResponses[result].event_iCalUId,
                        _id: response.calendarEventResponses[result].event_id,
                        _listAddress: addressAttendees,
                      },
                      description: response.calendarEventResponses[result].bodyPreview
                    });
    
                }
                sessionStorage.setItem("mmih_accessToken", response.user_access_token);
                localStorage.setItem('mmih_accessToken', response.user_access_token);
                this.setState({
                  calendarTrelloEvents: calendarTrelloEvents,
                  openEvent: false,
                  isLoaded: false
                });
    
              },
              error => {
                this.setState({
                  isLoaded: false
                });
    
                toast.notify("Error retrieving data" + error, {
                  duration: 3000
                });
              }
            );

          }).catch(error => {
            this.setState({
              isLoaded: false,
              error
            });
          });

        
      },
      error => {
        this.setState({
          isLoaded: false
        });

        toast.notify("Error retrieving data" + error, {
          duration: 3000
        });
      }
    );


  }
  handleEventUpdateTitleChange = e => {
    this.setState({
      openEventTitle: e.target.value
    });
  }
  handleUpdateChanges = (event) => {
    debugger;
    event.preventDefault();
    if (this.state.currentOpenEvent_trueData._openEventTitle === this.state.openEventTitle &&
      this.state.currentOpenEvent_trueData._text_add_emailItem === this.state.text_add_emailItem &&
      this.state.currentOpenEvent_trueData._openEventStartDate === this.state.openEventStartDate &&
      this.state.currentOpenEvent_trueData._openEventEndDate === this.state.openEventEndDate &&
      this.state.currentOpenEvent_trueData._openEventDescriptin === this.state.openEventDescriptin
    ) {
      toast.notify('No new data has been captured ', {
        duration: 3000
      });

      return;

    }

    const data = new FormData(event.target);
    let title = data.get('input_text_add_titleEvent');
    let start_date = data.get('input_text_start_dateEvent');
    let end_date = data.get('input_text_end_dateEvent');
    let email = data.get('input_text_add_emailEvent');
    let description = data.get('input_text__add_descriptionEvent');
    let task_type = data.get('input_text_taskTypesEvent');
    let boardtype = data.get('input_text_BoardTypesEvent');
    let cardId = '', laneId = '';
    const parseJwt = token => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        return null;
      }
    };

    let employeeNo = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.employeeNumber;
    let samaccountName = parseJwt(sessionStorage.getItem('mmih_auth_token')).sub.sAMAccountName;

    let attendingEmail = [];
    if (this.state.currentOpenEvent_trueData._text_add_emailItem.length !== this.state.text_add_emailItem.length) {
      for (let tt = 0; tt < this.state.text_add_emailItem.length; tt++) {

        attendingEmail.push({
          "name": (this.state.text_add_emailItem[tt] !== undefined || this.state.text_add_emailItem[tt] !== '') ? this.state.text_add_emailItem[tt].substring(0, this.state.text_add_emailItem[tt].lastIndexOf("@")) : '',
          "address": this.state.text_add_emailItem[tt],
          "type": "REQUIRED"
        });
        

      }
    }


    const objCreateCard = {
      key: this.props.data.key,
      token: this.props.data.token,
      laneId: '',
      cardId: '',
      cardName: '' + title,
      dueDate: end_date,
      cardDesc: description,
      employeeNo: employeeNo,
      samaccountName: samaccountName
    },
      objUpdateEventData = {


        eventId: this.state.department_id,
        eventICal_id: this.state.department_UUid,
        subject: (this.state.currentOpenEvent_trueData._openEventTitle === this.state.openEventTitle) ? '' : title,
        bodyContent: (this.state.currentOpenEvent_trueData._openEventDescriptin === this.state.openEventDescriptin) ? '' : description,
        startDate: (this.state.currentOpenEvent_trueData._openEventStartDate === this.state.openEventStartDate) ? '' : start_date,
        endDate: (this.state.currentOpenEvent_trueData._openEventEndDate === this.state.openEventEndDate) ? '' : end_date,
        calendarLocationName: '',
        attendeeList: attendingEmail,
        body: {
          content: (this.state.currentOpenEvent_trueData._openEventDescriptin === this.state.openEventDescriptin) ? '' : description,
          contentType: 'HTML'
        },
        worksiteId: '',        
        cardId:this.state.storeCardIdInfo,
        boardName: '',
        taskName:'',
        trelloKey :this.props.data.key,
        trelloToken: this.props.data.token

      },
      objUpdateCardData = {
        eventId: this.state.department_id,
        eventICal_id: this.state.department_UUid,
        cardDesc: description + (email.indexOf('@') > 0) ? ' attendees ' + email : '',
        bodyContent: description,
        body: {
          content: description,
          contentType: 'HTML'
        },
        cardId: this.state.storeCardIdInfo,
        cardName: title,
        subject: title,
        closed: true,
        dueDate: end_date,
        startDate: start_date,
        endDate: end_date,
        key: this.props.data.key,
        laneId: '',
        position: '',
        taskId: 0,
        token: this.props.data.token,
        employeeNo: employeeNo,
        samaccountName: samaccountName,
        calendarLocationName: '',
        attendeeList: (typeof email !== undefined || email !== '') ?
          [{
            "name": (email !== undefined || email !== '') ? email.substring(0, email.lastIndexOf("@")) : '',
            "address": email,
            "type": "REQUIRED"
          }] : [],
        boardName: ''
      };

    this.setState({
      isLoaded: true
    });
    if(this.state.TaskTypeState !== "Select task type"){
    if(typeof this.state.TaskTypeState.props.name !== undefined){
      objUpdateEventData.taskName = this.state.TaskTypeState.props.value;
    }
  }

    this.CardsService
      .retrieveWarehouseCalendarEventStoreInfo({ cardId: '', eventId: this.state.department_id, key: this.props.data.key, token: this.props.data.token })
      .then(res => {
        debugger;
        let searchEntry = true;
        cardId = res.data.cardId;

        //laneId = res.data.cards[i].idList;



        objUpdateCardData.cardId = cardId;
        objUpdateCardData.laneId = laneId;
        
        objUpdateEventData.cardId = cardId;
        objUpdateEventData.boardName= this.state.BoardTypeState;
        objUpdateEventData.trelloKey = this.props.data.key;
        objUpdateEventData.trelloToken= this.props.data.token;

        if (searchEntry) {

          this.CardsService.UpdateCardDetails(objUpdateCardData).then(res => {
            toast.notify(res.data.message, {
              duration: 3000
            });

          }).catch(error => {
            toast.notify('Error adding card ' + error, {
              duration: 3000
            });
          });

        }

        //update the ms graph
        this.CardsService.updateOffice365Event(objUpdateEventData).then(officeResponse => {

          toast.notify(officeResponse.data.message, {
            duration: 3000
          });


          debugger;
          this.CardsService.GetCalendarO365(this.props.data).then(res => res.data).then(
            response => {
              let calendarTrelloEvents = []
              for (let result = 0; result < response.calendarEventResponses.length; result++) {
                var start_date = new Date(response.calendarEventResponses[result].startDate);
                var end_date = new Date(response.calendarEventResponses[result].endDate);//'2019-11-04T07:00:00'

                debugger;


                calendarTrelloEvents.push(
                  {

                    title: response.calendarEventResponses[result].subject,
                    start: start_date.toISOString(),
                    end: end_date.toISOString(),
                    backgroundColor: '#2C3E50',
                    borderColor: '#2C3E50',
                    extendedProps: {
                      department_UUid: response.calendarEventResponses[result].event_iCalUId,
                      _id: response.calendarEventResponses[result].event_id,
                      _listAddress: [],
                    },
                    description: response.calendarEventResponses[result].bodyPreview
                  });
              }
              sessionStorage.setItem('mmih_accessToken', response.user_access_token);
              localStorage.setItem('mmih_accessToken', response.user_access_token);
              this.setState({
                calendarTrelloEvents: calendarTrelloEvents,
                isLoaded: false,
                openEvent: false
              });
            },
            error => {
              this.setState({
                isLoaded: false
              });
              toast.notify("Error retrieving data" + error, {
                duration: 3000
              });
            }
          );


        }).catch(error => {
          this.setState({
            isLoaded: false
          });
          toast.notify("Error updating calendar event " + error, {
            duration: 3000
          });

        });

      }, error => {
        this.CardsService.
          SearchAllTrelloCards({ key: this.props.data.key, token: this.props.data.token, query: this.state.currentOpenEvent_trueData._openEventTitle }).
          then(result => {
            debugger;
            let storeCardName = '', storeCardId = '', storeLaneId = '', storeBoardId = '', cardInfoData = result.data.cards;
            let searchEntry = false;
            for (let pp = 0; pp < cardInfoData.length; pp++) {
              if (cardInfoData[pp].name === this.state.currentOpenEvent_trueData._openEventTitle) {
                storeCardName = cardInfoData[pp].name;
                storeCardId = cardInfoData[pp].id;
                storeLaneId = cardInfoData[pp].idList;
                storeBoardId = cardInfoData[pp].idBoard;
                
                objUpdateEventData.worksiteId= storeBoardId;
                objUpdateEventData.cardId = storeCardId;
                objUpdateCardData.laneId =storeLaneId;
                for(let po = 0;po <this.state.BoardData.length; po++){
                  if(this.state.BoardData[po].id ===storeBoardId){
                        objUpdateEventData.boardName = this.state.BoardData[po].name; 
                        searchEntry = true;
                        break;
                        }
                  }
                  if(searchEntry) break;
        
              }
            }
            if (searchEntry) {
              objUpdateCardData.cardId = storeCardId ;
              objUpdateCardData.laneId = storeLaneId;
              this.CardsService.UpdateCardDetails(objUpdateCardData).then(res => {
                toast.notify(res.data.message, {
                  duration: 3000
                });
              }).catch(error => {
                toast.notify('Error adding card ' + error, {
                  duration: 3000
                });
              });
            }
            //update the ms graph
            this.CardsService.updateOffice365Event(objUpdateEventData).then(officeResponse => {
              toast.notify(officeResponse.data.message, {
                duration: 3000
              });
              debugger;
              this.CardsService.GetCalendarO365(this.props.data).then(res => res.data).then(
                response => {
                  let calendarTrelloEvents = []
                  for (let result = 0; result < response.calendarEventResponses.length; result++) {
                    var start_date = new Date(response.calendarEventResponses[result].startDate);
                    var end_date = new Date(response.calendarEventResponses[result].endDate);//'2019-11-04T07:00:00'
                    calendarTrelloEvents.push(
                      {
                        title: response.calendarEventResponses[result].subject,
                        start: start_date.toISOString(),
                        end: end_date.toISOString(),
                        backgroundColor: '#2C3E50',
                        borderColor: '#2C3E50',
                        extendedProps: {
                          department_UUid: response.calendarEventResponses[result].event_iCalUId,
                          _id: response.calendarEventResponses[result].event_id,
                          _listAddress: [],
                        },
                        description: response.calendarEventResponses[result].bodyPreview
                      });
                  }
                  sessionStorage.setItem('mmih_accessToken', response.user_access_token);
                  localStorage.setItem('mmih_accessToken', response.user_access_token);
                  this.setState({
                    calendarTrelloEvents: calendarTrelloEvents,
                    isLoaded: false,
                    openEvent: false
                  });
                },
                error => {
                  this.setState({
                    isLoaded: false
                  });
                  toast.notify("Error retrieving data" + error, {
                    duration: 3000
                  });
                }
              );
            }).catch(error => {
              this.setState({
                isLoaded: false
              });
              toast.notify("Error updating calendar event " + error, {
                duration: 3000
              });
            });

          }).catch(error => {
            this.setState({
              isLoaded: false,
              error
            });
          });

      }
      );

  }

  render() {

    const { classes } = this.props;
   
    return (!this.state.calendarTrelloEvents.length) ? <div className={classes.alignLoader}><Loader type="ThreeDots" color="#3f51b5" height={80} width={80} /></div> : (
      <div>

        <FullCalendar dateClick={this.handleDateClick} plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]} header={
          {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          }
        }
          navLinks={true}
          editable={true}
          eventLimit={true}
          events={this.state.calendarTrelloEvents}
          weekends={true}
          eventClick={(e) => { this.handleEventClickInfo(e) }}


        />

        <Dialog disableBackdropClick disableEscapeKeyDown onClose={this.handleClose} onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open} modal="true"
          //className={classes.dailogWidth}
          fullWidth
          maxWidth={'md'}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>

            <Grid container direction="row" alignItems="left">
              <Grid item>
                <Loader type="ThreeDots" color="#3f51b5" height={40} width={40}
                  visible={this.state.isLoaded} />
              </Grid>
              <Grid item>
                &nbsp;  Create event
                                </Grid>
            </Grid>
          </DialogTitle>

          <ValidatorForm onSubmit={this.handleSubmitData} onError={errors => console.log(errors)} ref="form" id='id_ValidatorForm'>
            <DialogContent dividers>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <span className="star">*</span><ShortTextIcon />
                </Grid>
                <Grid item xs={11}>
                  <TextValidator
                    id="input_text_add_title"
                    name='input_text_add_title'
                    multiline
                    label="Add Subject"
                    variant="filled"
                    className={classes.textfieldWidth}
                    value={this.state.text_add_title}
                    onChange={this.handleTitleChange}
                    validators={['required']}
                    errorMessages={['this field is required']} />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth} >
                <Grid item xs={1}>
                  <span className="star">*</span>  <EventAvailableIcon />
                </Grid>
                <Grid item xs={5}>
                  <TextValidator
                    id="input_text_start_date"
                    name='input_text_start_date'
                    label="Start Date"
                    type="datetime-local"
                    //defaultValue={this.state.text_start_date}
                    onChange={this.handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.text_start_date}
                    validators={['required']}
                    errorMessages={['this field is required']} />
                </Grid>
                <Grid item xs={5}>
                  <TextValidator
                    id="input_text_end_date"
                    name='input_text_end_date'
                    label="End Date"
                    type="datetime-local"
                    //defaultValue={this.state.text_end_date}
                    onChange={this.handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.text_end_date}
                    validators={['required', 'isEndDateGreaterStartDateCheck']}
                    errorMessages={['this field is required', 'Start date cannot be greater than end date.']}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <AlternateEmailIcon />
                </Grid>
                <Grid item xs={11}  >
                  {this.state.text_add_emailItem.map(item => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDeleteEmail(item)}
                      >
                        &times;
            </button>
                    </div>
                  ))}

                  <TextValidator
                    id="input_text_add_email"
                    label="Add email and press enter/tab"
                    name='input_text_add_email'
                    className={classes.textfieldWidth}
                    onChange={this.handleChangeEmail}
                    value={this.state.text_add_email}
                    validators={['isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                    onPaste={this.handlePasteEmail}
                    onKeyDown={this.handleKeyDown}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <SubjectIcon />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    id="input_text__add_description"
                    name='input_text__add_description'
                    label="Add Description"
                    multiline
                    margin="normal"
                    variant="filled"
                    className={classes.textfieldWidth}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <span className="star">*</span> <CheckIcon />
                </Grid>
                <Grid item xs={11} className={classes.textfieldWidth}>
                  <SelectValidator
                    validators={['isTaskTypeSelected', 'required']}
                    errorMessages={['Task type is required']}
                    id="input_text_taskTypes"
                    name="input_text_taskTypes"
                    //defaultValue={this.state.TaskTypeState}
                    value={this.state.TaskTypeState}
                    onChange={(e) => this.handleChangeTaskTypes(e)}
                    className={classes.textfieldWidth}>
                    <MenuItem value={this.state.TaskTypeState}>Select task type</MenuItem>
                    {this.renderTaskTypesOptions()}
                  </SelectValidator>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <span className="star">*</span> <DomainIcon />
                </Grid>
                <Grid item xs={11} className={classes.textfieldWidth}>
                  <SelectValidator
                    validators={['isWorksiteBoardSelected', 'required']}
                    errorMessages={['Board type is required']}
                    id="input_text_BoardTypes"
                    name="input_text_BoardTypes"
                    //defaultValue={this.state.BoardTypeState}
                    value={this.state.BoardTypeState}
                    onChange={(e) => this.handleChangeBoardTypes(e)}
                    className={classes.textfieldWidth}>
                    <MenuItem value={this.state.BoardTypeState}>{this.state.BoardTypeState}</MenuItem>
                    {this.renderBoardTypeStateOptions()}
                  </SelectValidator>
                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" name="submit" id="submit" autoFocus  variant="contained" disabled={this.state.isLoaded}>
                Save changes
                        </Button>
            </DialogActions>
          </ValidatorForm>

        </Dialog>

        <Dialog disableBackdropClick disableEscapeKeyDown onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.openEvent} modal="true"
          //className={classes.dailogWidth}
          fullWidth
          maxWidth={'md'}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            <Grid container direction="row" alignItems="left">
              <Grid item>
                <Loader type="ThreeDots" color="#3f51b5" height={40} width={40}
                  visible={this.state.isLoaded} />
              </Grid>
              <Grid item>
                &nbsp;  Update event
                                </Grid>
            </Grid>

          </DialogTitle>
          <LoadingOverlay
                    active={this.state.overlay}
                    spinner={<Loader type="ThreeDots" color="#3f51b5" height={80} width={80} />}

                 >
          <ValidatorForm onSubmit={this.handleUpdateChanges} onError={errors => console.log(errors)} ref="form" id='id_ValidatorForm'>
            <DialogContent dividers>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>

                <Grid item xs={1}>
                  <span className="star">*</span><ShortTextIcon />
                </Grid>
                <Grid item xs={11}>

                  <TextField
                    id="input_text_add_titleEvent"
                    name='input_text_add_titleEvent'
                    multiline
                    label="Add Subject"
                    variant="filled"
                    className={classes.textfieldWidth}
                    value={this.state.openEventTitle}
                    onChange={this.handleEventUpdateTitleChange}
                    readOnly
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth} >
                <Grid item xs={1}>
                  <span className="star">*</span> <EventAvailableIcon />
                </Grid>
                <Grid item xs={5}>
                  <TextValidator
                    id="input_text_start_dateEvent"
                    name='input_text_start_dateEvent'
                    label="Start Date"
                    type="datetime-local"

                    //defaultValue={this.state.text_start_date}
                    onChange={this.handleStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.openEventStartDate}
                    validators={['required']}
                    errorMessages={['this field is required']} />
                </Grid>
                <Grid item xs={5}>
                  <TextValidator
                    id="input_text_end_dateEvent"
                    name='input_text_end_dateEvent'
                    label="End Date"
                    type="datetime-local"

                    //defaultValue={this.state.text_end_date}
                    onChange={this.handleEndDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.openEventEndDate}
                    validators={['required', 'isEndDateGreaterStartDateCheck']}
                    errorMessages={['this field is required', 'Start date cannot be greater than end date.']}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <AlternateEmailIcon />
                </Grid>
                <Grid item xs={11}  >
                  {this.state.text_add_emailItem.map(item => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleDeleteEmail(item)}
                      >
                        &times;
            </button>
                    </div>
                  ))}
                  <TextValidator
                    id="input_text_add_emailEvent"
                    label="Add email and press enter/tab"
                    name='input_text_add_emailEvent'
                    className={classes.textfieldWidth}
                    onChange={this.handleChangeEmail}
                    value={this.state.text_add_email}
                    validators={['isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                    onPaste={this.handlePasteEmail}
                    onKeyDown={this.handleKeyDown}


                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <SubjectIcon />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    id="input_text__add_descriptionEvent"
                    name='input_text__add_descriptionEvent'
                    label="Add Description"
                    value={this.state.openEventDescriptin}
                    multiline
                    margin="normal"
                    variant="filled"
                    className={classes.textfieldWidth}
                    onChange={(e) => this.handleChangeEventDescriptin(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" id="newBoardTypeStateFlow1" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <CheckIcon />
                </Grid>
                <Grid item xs={11} className={classes.textfieldWidth}>
                  <SelectValidator

                    id="input_text_taskTypes"
                    name="input_text_taskTypes"
                    //defaultValue={this.state.TaskTypeState}
                    value={this.state.TaskTypeState}
                    onChange={(e) => this.handleChangeTaskTypes(e)}
                    className={classes.textfieldWidth}>
                    <MenuItem value={this.state.TaskTypeState}>{this.state.TaskTypeState}</MenuItem>
                    {this.renderTaskTypesOptions()}
                  </SelectValidator>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" id="newBoardTypeStateFlow" className={classes.textfieldWidth}>
                <Grid item xs={1}>
                  <DomainIcon />
                </Grid>
                <Grid item xs={11} className={classes.textfieldWidth}>
                  <SelectValidator

                    id="input_text_BoardTypes"
                    name="input_text_BoardTypes"
                    //defaultValue={this.state.BoardTypeState}
                    value={this.state.BoardTypeState}
                    onChange={(e) => this.handleChangeBoardTypes(e)}
                    className={classes.textfieldWidth}>
                    <MenuItem value={this.state.BoardTypeState}>{this.state.BoardTypeState}</MenuItem>
                    {this.renderBoardTypeStateOptions()}
                  </SelectValidator>
                </Grid>
              </Grid>


            </DialogContent>
            <DialogActions>
              <Button color="primary"
                type="submit"
                variant="contained" autoFocus id="deleteCalendarEventID" onClick={this.handleDeleteChanges} disabled={this.state.isLoaded}>
                delete
                        </Button>
              <Button color="primary"
                type="submit"
                variant="contained" type="submit" autoFocus id="updateCalendarEventID" disabled={this.state.isLoaded}>
                update changes
                        </Button>

            </DialogActions>
          </ValidatorForm>
          </LoadingOverlay>
        </Dialog>
      </div>




    );
  }
}
export default withStyles(styles)(ToDoCalendarLayoutHub);
