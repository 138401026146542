import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
    CardForm,
    CardHeader,
    CardRightContent,
    CardTitle,
    CardWrapper,
    Detail
} from './customForm/styles/Base'
import {AddButton, CancelButton} from './customForm/styles/Elements'
import EditableLabel from './customForm/widgets/EditableLabel'

class CustomCardForm extends Component {
    updateField = (field, value) => {
        this.setState({[field]: value})
    }

    handleAdd = () => {
        this.props.onAdd(this.state)
    }

    render() {
        const {onCancel, t} = this.props
        return (
            <CardForm>
                <CardWrapper autoFocus>
                    <CardHeader>
                        <CardTitle>
                            <EditableLabel placeholder={"Title"} onChange={val => this.updateField('title', val)} autoFocus  />
                        </CardTitle>
                        <hr/>
                        {/*<CardRightContent>*/}
                        {/*    <EditableLabel placeholder={t('placeholder.label')} onChange={val => this.updateField('label', val)} />*/}
                        {/*</CardRightContent>*/}
                    </CardHeader>
                    <Detail>
                        <EditableLabel placeholder={"Description"} onChange={val => this.updateField('description', val)}  />
                    </Detail>
                </CardWrapper>
                <AddButton onClick={this.handleAdd}>{t('button.Add card')}</AddButton>
                <CancelButton onClick={onCancel}>{t('button.Cancel')}</CancelButton>
            </CardForm>
        )
    }
}

CustomCardForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
}

CustomCardForm.defaultProps = {
}

export default CustomCardForm