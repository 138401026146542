import React, {  useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { GraphData, GraphDataMonths } from './constantNavigate.js';
import { Line, LineChart, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';
import * as pbi from 'powerbi-client';
import toast from 'toasted-notes';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import PowerBiService from "../shared/PowerBiService";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',

  },
  paper2: {
    height: 300,
    padding: theme.spacing(2),
    margin: 'auto',
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 120,
  },
  dashboardLayout: {
    boxsizing: 'border-box',
    borderradius: '4px',
    boxshadow: '0px 1px 3px 0px #00000033, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #0000001f',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundcolor: '#fff',
    flexwrap: 'wrap',
  },
  central:{
    padding: 'inherit',
    textAlign: 'center',
  },
}));



const DashboardLayoutHub = () => {

   const base64Credentials =   sessionStorage.getItem("mmih_flow") ;

  useEffect(() => {

    //Get AccessToken


    const ObjectReportAccess =
        {
      "datasets": [
        {
          "id": "f6738574-fe20-4119-b80d-336c789e7875"
        }
      ],
      "reports": [
        {

          "id": "18ec1db6-0272-4fd5-863f-2ef8caeedb8b"
        }
      ]
    }

    PowerBiService.getAccessToken(base64Credentials)
        .then(res => {
          console.log(JSON.stringify(res));

          const models = pbi.models;
          const AccessToken = res.data.access_token

          PowerBiService.getReportMyProductionReport(ObjectReportAccess,AccessToken)
              .then(res => {
                console.log('DATA -->'  + res.data);

                const config = {
                  type: 'report',
                  id: "18ec1db6-0272-4fd5-863f-2ef8caeedb8b", //Report ID
                  tokenType: models.TokenType.Embed,
                  accessToken: res.data.token,
                  embedUrl: "https://app.powerbi.com/reportEmbed?reportId=18ec1db6-0272-4fd5-863f-2ef8caeedb8&ctid=366b8d18-99ea-436a-824b-1ca89c369476&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D&pageName=ReportSection7217e2e6dad3519b6810",
                  permissions: models.Permissions.Read,
                  width: "100%",
                  heigh: "100%",
                  settings:
                      {
                        filterPaneEnabled: false,
                      }
                };

                debugger;

                var reportContainer = document.getElementById('dashboardContainer');
                var report = window.powerbi.embed(reportContainer, config);
              })
              .catch(error => {
                toast.notify("Error retrieving data", {
                  duration: 3000
                });
              })
        })
        .catch(error => {
          toast.notify("Error retrieving data", {
            duration: 3000
          });
        });


  });




  const classes = useStyles();
  return (
      <form>
        <div className={classes.root}>
          <Grid container spacing={5} >
            {/* Customer Centricity... */}

            <Grid item xs={12} md={8} lg={12}>

              <Paper className={classes.paper}>


                <Grid item xs >
                  <ExpansionPanel   defaultExpanded>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >

                      <Typography className={classes.secondaryHeading}><b>>> MY PRODUCTION</b></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                      <div id="dashboardContainer" style={{width: '1550px',height:'800px', borderWidth: 0}}></div>

                      {/*      <iframe width="100%" height="800"
                              src="https://app.powerbi.com/reportEmbed?reportId=18ec1db6-0272-4fd5-863f-2ef8caeedb8b&autoAuth=true&ctid=366b8d18-99ea-436a-824b-1ca89c369476&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D&ReportSection28d34217d6103c1192c4"
                              frameBorder="0" allowFullScreen="true"></iframe>
*/}

                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>

              </Paper>
            </Grid>


          </Grid>
        </div>
      </form>
  );

}
export default DashboardLayoutHub;
