import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { deepOrange, green } from '@material-ui/core/colors';
import {creation_notification, notification_cardInfo} from './notificationTrello.js'

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: `${theme.spacing(1)}px auto`,
  },
  paperBig: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    //margin: `${theme.spacing(1)}px auto`,
    height: '500px'
  },
  square: {
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
});

class InboxLayoutHub extends Component {
  constructor(props){
    super(props);
    this.state = {
      title: '',
    date:'',
    description:''
    }
    this.handleClick=this.handleClick.bind(this);

  }
  handleClick =(e, clickBody) =>{
    e.preventDefault();
    debugger;
    let foundCard = false,foundCardID ='';
    let cardList = notification_cardInfo;
    cardList.forEach(cardListEntry =>{
      if(clickBody.card_id.trim()===cardListEntry.id.trim()){
        foundCard = true;
        foundCardID = cardListEntry.id;
        this.setState({
          title: cardListEntry.name,
          date:cardListEntry.due,
          description:cardListEntry.desc
        });        
      }
      
    }
    );
    if(foundCard){
      // Service Call to get Card infor by Card Id
      /**
       * this.CardService.getCardInfo(foundCardID).
       * then(foundCardIDResponse => {
       * 
       * this.setState({
          title: foundCardIDResponse.name,
          date: foundCardIDResponse.due,
          description: foundCardIDResponse.desc
        })
       * 
       * }).catch(error =>{
       * 
       * });
       */

    }
  }

  

  render() {
    const { classes } = this.props;
    const getNotification = (creation_notification) => {
    
      return creation_notification !== 0
        ? creation_notification.map((notificationBody, index) => {
          return (
            <Paper className={classes.paper} key={index}>
              <IconButton onClick={(e) =>this.handleClick(e,notificationBody)}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar variant="square" className={classes.square}>{notificationBody.taskType.taskName.charAt(0)}</Avatar>
                  </Grid>
                  <Grid item xs >
                    <Typography noWrap>
                       {notificationBody.taskType.taskName}
                  </Typography>
                  </Grid>                
                </Grid>
                </IconButton>
              </Paper>
          );
        })
        : <h1>Error Logging</h1>;
    };

    return (
      <React.Fragment>
        <Grid container wrap="nowrap" spacing={3}>
          <Grid item xs={12} sm={4} >
            {getNotification(creation_notification)}
          </Grid>
          <Grid item xs={12} sm={8} >
            <Paper className={classes.paperBig}>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1">
                    {(this.state.title !== '')? 'Task Name: '+this.state.title : ''}
                </Typography>              
                  <Typography variant="body2" gutterBottom>
                  {(this.state.date !== '')? 'Task Date: '+this.state.date : ''}
                </Typography>
                  <Typography variant="body2" color="textSecondary">
                  {(this.state.description !== '')? 'Task Description: '+this.state.description : ''}
                </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                    Remove
                </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">$19.00</Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(InboxLayoutHub);