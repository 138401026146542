import React, { Component } from "react";
import { ReactSlackChat } from "react-slack-chat";
import avatarService from "../shared/AvatarService";
import "./style.css";

export default class SlackChatUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            avatar: ''
        };

        this.avatarService = new avatarService();
    }

    componentDidMount() {
        this.avatarService
            .GetTrelloBoardAvatar(this.props.data)
            .then(res => res.data)
            .then(response => {
                    this.setState({
                        avatar: response,
                    });
                },error => {
                    console.log("Error response Avatar", error);
                }
            );
    }

    render() {

    return (
      <ReactSlackChat
        botName={this.state.avatar.fullName}// VisitorID, CorpID, Email, IP address etc.
        apiToken="eG94Yi02NzIxNzQ1NDM1NDItODE2MjU3ODI0NTQ5LUZqWjRxaXlVb0FBWkZyak1VZGx4VTV1Yg=="
        channels={[
            {
            name: "technicalsupport"
            }
        ]}
        helpText="GetUp! Let's Chat?"
        themeColor="#673ab7"
        userImage={(this.state.avatar.avatarUrl !== undefined)?this.state.avatar.avatarUrl + "/170.png":'https://i.picsum.photos/id/2/367/267.jpg'}
        debugMode={false}
        closeChatButton={true}
        hooks={[
          {
            /* My Custom Hook */
            id: "getSystemInfo",
            action: () => Promise.resolve("MY SYSTEM INFO!")
          }
        ]}
      />
    );
  }
}
